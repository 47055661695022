import { getDisplayedData } from '@/utils/displayedData';
import Btn from '@/components/Btn/Btn.vue';
import Modal from '@/components/Modal/Modal.vue';
import FinancialInfoForm from './FinancialInfoForm/FinancialInfoForm.vue';
import FinancialInfoTables from '@/components/ClientInfo/FinancialInfo/FinancialInfoTables/FinancialInfoTables.vue';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';

export default {
  name: 'FinancialInfo',
  props: {
    financialInfo: { type: Object, default: null },
    clientId: { type: String },
    planId: { type: String },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-first',
    },
  },
  data: () => ({
    generalInfoLabels: [
      {
        label: 'Plan Type',
        value: 'typeOfPlan.name',
      },
      {
        label: 'Plan Start Year',
        value: 'planStartYear',
      },
      {
        label: 'Regional Cost',
        value: 'regionalCost',
        type: 'percentage',
      },
      {
        label: 'Inflation Cost Escalation',
        value: 'inflationCostEscalation',
        type: 'percentage',
      },
      {
        label: 'Soft Cost',
        value: 'softCostPercentage',
        type: 'percentage',
      },
    ],
    expanded: [],
  }),
  components: { Modal, Btn, FinancialInfoForm, FinancialInfoTables },
  computed: {
    fundingSources() {
      return this.financialInfo?.fundingSources?.slice() || [];
    },
    showEditFinancial() {
      return accessByPermissions(PERMISSIONS.EDIT_FINANCIAL, this.currentUser);
    },
    displayedGeneralInfoData() {
      return this.financialInfo ? getDisplayedData(this.generalInfoLabels, this.financialInfo) : [];
    },
    financialInfoId() {
      return this.financialInfo.id;
    },
    planStartYear() {
      return this.financialInfo?.planStartYear;
    },
  },
};
