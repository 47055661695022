import currencyFilter from '@/filters/currency';
import DataTable from '@/components/DataTable/DataTable.vue';

export default {
  name: 'SimpleEditableTable',
  components: { DataTable },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
    totalLabel: {
      type: String,
      default: '',
    },
  },
  data: () => ({}),
  computed: {
    firstHeader() {
      return this.displayedHeaders[0];
    },
    displayedHeaders() {
      return this.headers.map(item => {
        item.sortable = false;
        return item;
      });
    },
    displayedItems() {
      const result = this.items.slice().map((item, index) => {
        item.key = index;
        return item;
      });
      result[result.length - 1].className = 'simple-table-total-cell';
      result[result.length - 1].noEdit = true;
      return result;
    },
    mainHeaders() {
      return this.displayedHeaders.slice(1);
    },
  },
  methods: {
    onDropdownChange(data, header) {
      this.$emit('dropdownChange', {
        data,
        header,
      });
    },
    hideInput(event) {
      event.currentTarget.parentNode.classList.remove('-hide-simple-table-input-label');
    },
    cellLayout(header, cell, fixedNumber) {
      let cellValue = cell.value;
      if (cellValue === null || cellValue === undefined) {
        return '-';
      }
      if (typeof cellValue === 'number') {
        cellValue = parseFloat(cellValue.toFixed(fixedNumber || 2));
      } else {
        return cellValue;
      }
      if (header.currency) {
        if (cellValue < 0) {
          return `<span class="negative-budget">(${currencyFilter(cellValue * -1)})</span>`;
        }
        return currencyFilter(cellValue);
      }
      return cellValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    showInput(event) {
      const parent = event.currentTarget.parentNode;
      parent.classList.add('-hide-simple-table-input-label');
      parent.querySelector('input').focus();
    },
    onInputChange(event, cell, row) {
      const value = event.currentTarget.value.toString().replace(/[^0-9.]/g, '');
      row[cell.value].value = value === '' ? null : parseFloat(parseFloat(value).toFixed(cell.fixedNumber || 2));
      this.$emit('update:items', this.displayedItems);
      event.currentTarget.parentNode.classList.remove('-hide-simple-table-input-label');
    },
  },
};
