var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloMutation',_vm._g({staticClass:"complete-component",attrs:{"mutation":_vm.COMPLETE_COMPONENT_OF_NEED_MUTATION,"variables":{
    input: {
      id: _vm.component.id,
      yearCompleted: _vm.component.yearCompleted,
      actualCost: _vm.component.actualCost,
      componentNotes: _vm.component.componentNotes,
    },
  },"update":_vm.updateComponents},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var mutate = ref.mutate;
  var loading = ref.loading;
  var error = ref.error;
return [(!loading)?_c('alert',{attrs:{"align":"center","error":error}}):_vm._e(),_c('v-form',{ref:"form",staticClass:"complete-component-form",attrs:{"lazy-validation":"","aria-busy":loading,"disabled":loading,"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit(mutate)}}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-select',{attrs:{"items":_vm.years,"rules":_vm.yearCompletedRules,"label":"Year completed","required":""},model:{value:(_vm.component.yearCompleted),callback:function ($$v) {_vm.$set(_vm.component, "yearCompleted", $$v)},expression:"component.yearCompleted"}}),_c('v-text-field',{staticClass:"mt-1",attrs:{"type":"number","rules":_vm.actualCostRules,"label":"Actual cost","autocomplete":"off","prefix":"$","validate-on-blur":""},model:{value:(_vm.component.actualCost),callback:function ($$v) {_vm.$set(_vm.component, "actualCost", _vm._n($$v))},expression:"component.actualCost"}}),_c('v-textarea',{staticClass:"mt-1",attrs:{"rows":"1","auto-grow":"","label":"Notes of Component","autocomplete":"off"},model:{value:(_vm.component.componentNotes),callback:function ($$v) {_vm.$set(_vm.component, "componentNotes", $$v)},expression:"component.componentNotes"}})],1)],1),_c('div',{staticClass:"mt-8 btns-bar"},[_c('btn',{attrs:{"type":"submit","width":"300","disabled":loading}},[_vm._v("Complete")])],1)],1)]}}])},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }