const ROLES = {
  admin: 'Admin',
  readonly: 'ICS - Read-Only',
  viewer: 'Client - Viewer',
  client_manager: 'Client - Manager',
  ics_manager: 'ICS - Manager',
  user: 'User',
};

export default ROLES;
