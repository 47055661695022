import FacilityForm from '@/components/Clients/ClientForm/Facilities/FacilityForm/FacilityForm.vue';
import ADD_FACILITY_MUTATION from '@/graphql/mutations/addFacility.gql';
import eventHub from '@/utils/eventHub';

export default {
  name: 'AddFacility',
  components: { FacilityForm },
  props: {
    client: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    ADD_FACILITY_MUTATION,
    facility: {
      name: null,
      squareFootage: null,
      age: null,
      occupancy: null,
      capacity: null,
      use: null,
      siteSize: null,
      replacementCost: null,
      photoFile: null,
      summary: null,
      type: null,
    },
  }),
  methods: {
    onSubmit(mutate) {
      this.facility = { ...this.facility };
      this.$nextTick(() => {
        mutate();
      });
    },
    updateFacilities(store, { data: { addFacility } }) {
      if (addFacility) {
        const facilities = [...this.client.facilities];
        facilities.unshift(addFacility);
        this.client.facilities = facilities;
        eventHub.$emit('show-snackbar', {
          color: 'success',
          text: `Facility ${addFacility.name} was added successfully!`,
        });
      } else {
        eventHub.$emit('show-snackbar', {
          color: 'error',
          text: `Error! Something went wrong... Please try later!`,
        });
      }
    },
  },
};
