import ContactForm from '@/components/Clients/ClientForm/Contacts/ContactForm/ContactForm.vue';
import ADD_CONTACT_MUTATION from '@/graphql/mutations/addContact.gql';
import eventHub from '@/utils/eventHub';

export default {
  name: 'AddContact',
  components: { ContactForm },
  props: {
    client: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    ADD_CONTACT_MUTATION,
    contact: {
      name: null,
      jobTitle: null,
      phones: [{ type: null, phone: null }],
      isPrimary: null,
      email: null,
      location: null,
      countYearsTogether: null,
    },
  }),
  methods: {
    updateContacts(store, { data: { addContact } }) {
      if (addContact) {
        if (addContact.isPrimary) {
          this.client.contacts.forEach(item => (item.isPrimary = false));
        }
        const contacts = [...this.client.contacts];
        contacts.push(addContact);
        this.client.contacts = contacts;
        eventHub.$emit('show-snackbar', {
          color: 'success',
          text: `Contact ${addContact.name} was added successfully!`,
        });
      } else {
        eventHub.$emit('show-snackbar', {
          color: 'error',
          text: `Error! Something went wrong... Please try later!`,
        });
      }
    },
  },
};
