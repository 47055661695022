var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panels',{staticClass:"list-of-sources",attrs:{"flat":"","value":_vm.expanded,"multiple":""}},[_c('v-expansion-panel',[_c('expansion-panel-header',[_vm._v(" Funding Sources ")]),_c('v-expansion-panel-content',{staticClass:"funding-table"},[_c('modal',{attrs:{"title":"Add Funding Source","width":"480px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showAddBtn),expression:"showAddBtn"}],staticClass:"add-button",attrs:{"width":"234"}},'btn',attrs,false),on),[_vm._v("Add Funding Source")])]}},{key:"default",fn:function(ref){
var close = ref.close;
return [_c('funding-source-form',{attrs:{"planId":_vm.planId,"financialInfoId":_vm.financialInfoId,"clientId":_vm.clientId},on:{"done":close}})]}}])}),_c('data-table',{attrs:{"headers":_vm.displayedFundingHeaders,"items":_vm.fundingSources,"itemsPerPage":-1},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-actions-bar"},[_c('modal',{attrs:{"title":"Edit Funding Source","width":"480px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.showEditBtn)?_c('v-icon',_vm._g(_vm._b({staticClass:"action edit mr-2",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("far fa-edit")]):_vm._e()]}},{key:"default",fn:function(ref){
var close = ref.close;
return [_c('funding-source-form',{attrs:{"planId":_vm.planId,"financialInfoId":_vm.financialInfoId,"fundingSource":item,"clientId":_vm.clientId},on:{"done":close}})]}}],null,true)}),(_vm.showDeleteBtn)?_c('confirmation-modal',{attrs:{"mutation":_vm.DELETE_FUNDING_SOURCE_MUTATION,"variables":{ id: item.id },"title":"Delete Funding Source","ok-btn":{ label: 'Delete', loading: _vm.loadingConfirmation },"update":_vm.onDeleteFunding(item)},on:{"close":_vm.onModalClose},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('ApolloQuery',{attrs:{"query":_vm.COMPONENTS_OF_NEED_BY_FUNDING_SOURCE,"variables":{ fundingSourceId: item.id },"update":_vm.onQueryFinish},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [(_vm.loadingConfirmation)?_c('div',{staticClass:"loader-block"},[_c('div',{staticClass:"loader mt-4"})]):[(_vm.confirmResult && _vm.confirmResult.componentsOfNeedByFundingSourceId.length > 0)?_c('p',{staticClass:"alert-msg"},[_vm._v(" The client added components to the funding source. "),_c('br'),_vm._v("Deleting the funding source will delete components. ")]):_vm._e(),_c('p',[_vm._v("Are you sure you want to delete the funding source?")])]]}}],null,true)})]},proxy:true},{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action",attrs:{"small":""}},'v-icon',{ attrs: attrs },false),on),[_vm._v(" far fa-trash-alt ")])]}}],null,true)}):_vm._e()],1)]}}])})],1)],1),_c('v-expansion-panel',[_c('expansion-panel-header',[_vm._v(" Infusion Sources ")]),_c('v-expansion-panel-content',{staticClass:"funding-table"},[_c('modal',{attrs:{"title":"Add Infusion","width":"480px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showAddBtn),expression:"showAddBtn"}],staticClass:"add-button",attrs:{"width":"234"}},'btn',attrs,false),on),[_vm._v("Add Infusion")])]}},{key:"default",fn:function(ref){
var close = ref.close;
return [_c('infusion-source-form',{attrs:{"clientId":_vm.clientId,"fundingSources":_vm.fundingSources,"planStartYear":_vm.planStartYear},on:{"done":close}})]}}])}),_c('data-table',{attrs:{"headers":_vm.displayedInfusionHeaders,"items":_vm.displayedInfusionItems,"itemsPerPage":-1},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-actions-bar"},[_c('modal',{attrs:{"title":"Edit Infusion","width":"480px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.showEditBtn)?_c('v-icon',_vm._g(_vm._b({staticClass:"action edit mr-2",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("far fa-edit")]):_vm._e()]}},{key:"default",fn:function(ref){
var close = ref.close;
return [_c('infusion-source-form',{attrs:{"infusionSource":item,"clientId":_vm.clientId,"fundingSources":_vm.fundingSources,"planStartYear":_vm.planStartYear},on:{"done":close}})]}}],null,true)}),(_vm.showDeleteBtn)?_c('confirmation-modal',{attrs:{"mutation":_vm.DELETE_CAPITAL_INFUSION_MUTATION,"variables":{ id: item.id },"message":"Are you sure you want to delete infusion?","title":"Delete Infusion","ok-btn":{ label: 'Delete' },"update":_vm.onDeleteInfusion(item)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action",attrs:{"small":""}},'v-icon',{ attrs: attrs },false),on),[_vm._v(" far fa-trash-alt ")])]}}],null,true)}):_vm._e()],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }