import Btn from '@/components/Btn/Btn.vue';
import DataTable from '@/components/DataTable/DataTable.vue';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import CLIENTS_QUERY from '@/graphql/queries/clients.gql';
import CLIENT_QUERY from '@/graphql/queries/client.gql';
import ENERGY_USAGE_QUERY from '@/graphql/queries/energyUsage.gql';
import PERMISSIONS from '@/enums/permissions';
import accessByPermissions from '@/services/userPermissions';
import { generateRangeArray } from '@/utils/generator';

const ENERGY_USAGE_TYPE = {
  electric: 'electric',
  gas: 'gas',
  steam: 'steam',
  fuelOil: 'fuelOil',
  propane: 'propane',
};

export default {
  name: 'ComparisonGrid',
  components: { Btn, DataTable },
  props: {
    client: { type: Object, default: () => null },
    facilities: { type: Array, default: () => [] },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
    clients: {
      query: CLIENTS_QUERY,
      fetchPolicy: 'network-only',
      skip() {
        return !accessByPermissions(PERMISSIONS.VIEW_CLIENTS, this.currentUser);
      },
    },
  },
  data: vm => ({
    tab: vm.activeTab,
    currentUser: null,
    loadingConfirmation: true,
    confirmResult: {},
    editableField: '',
    clientComparison: 1,
    valid: true,
    tableLines: [
      { text: 'Year', value: 'year' },
      { text: 'Electricity', value: ENERGY_USAGE_TYPE.electric, unitType: 'kWh' },
      { text: 'Natural Gas', value: ENERGY_USAGE_TYPE.gas, unitType: 'BTUs' },
      { text: 'Steam', value: ENERGY_USAGE_TYPE.steam, unitType: 'BTUs' },
      { text: 'Fuel Oil', value: ENERGY_USAGE_TYPE.fuelOil, unitType: 'BTUs' },
      { text: 'Propane', value: ENERGY_USAGE_TYPE.propane, unitType: 'BTUs' },
      { text: 'EUI', value: 'eui' },
      { text: 'Square Footage', value: 'square' },
      { text: '$/Sqft', value: 'sqft' },
    ],
    columnClass: [
      'base-comparison-column comparison-column-border',
      'info-comparison-column comparison-column-border',
      'info-comparison-column',
    ],
    headerData: [
      {
        id: null,
        facilityId: null,
        year: null,
        facilities: [],
        years: [],
      },
    ],
    clientComparisonData: {
      facilityId: null,
      year: vm.client?.plans[0]?.financialInfo?.planStartYear || null,
    },
    clientDropdownFilter: '',
    energyUsage: {},
    comparisonTableData: [],
  }),
  watch: {
    client: {
      handler() {
        if (this.client) {
          this.fillComparisonTableData();
        }
      },
      immediate: true,
    },
  },
  computed: {
    planStartYear() {
      return this.client?.plans[0]?.financialInfo?.planStartYear || null;
    },
    primarySelectedItems() {
      return [this.client.name];
    },
    clientFacilities() {
      return this.client ? this.client.facilities : [];
    },
    clientYears() {
      return this.getYearRange(Number(this.planStartYear) || null);
    },
    clientsList() {
      return this.clients?.filter(client => client.name.toLowerCase().includes(this.clientDropdownFilter.toLowerCase())) || [this.client];
    },
    primaryClintEnergy() {
      return this.getClientEnergy(this.clientComparisonData.facilityId, this.clientComparisonData.year);
    },
    selectedClientFacility() {
      return this.clientFacilities.find(item => item.id === this.clientComparisonData.facilityId);
    },
  },
  methods: {
    fillComparisonTableData() {
      const firstColumn = this.tableLines.map(item => {
        let newElement = {};
        newElement[item.value] = item.text;
        return newElement;
      });
      const secondColumn = this.fillComparisonColumn(
        this.primaryClintEnergy,
        'btu',
        this.clientComparisonData.year,
        this.selectedClientFacility?.squareFootage
      );
      let columnsTables = [firstColumn, secondColumn];
      for (let index = 0; index < this.clientComparison; index++) {
        columnsTables.splice(
          index + 2,
          1,
          this.fillComparisonColumn(
            this.getClientEnergy(this.headerData[index].facilityId, this.headerData[index].year),
            'btu',
            this.headerData[index].year,
            this.headerData[index].facilities.find(item => item.id === this.headerData[index].facilityId)?.squareFootage
          )
        );
      }
      this.comparisonTableData = columnsTables;
    },
    fillComparisonColumn(tables, prop, year, squareFootage) {
      let result = this.tableLines.map(item => {
        let newElement = {};
        newElement[item.value] = undefined;
        return newElement;
      });
      try {
        if (tables && prop) {
          result = [
            { year: year },
            { [ENERGY_USAGE_TYPE.electric]: this.getTableProp(tables[ENERGY_USAGE_TYPE.electric], 12, 'usage') },
            { [ENERGY_USAGE_TYPE.gas]: this.getTableProp(tables[ENERGY_USAGE_TYPE.gas], 12, prop) },
            { [ENERGY_USAGE_TYPE.steam]: this.getTableProp(tables[ENERGY_USAGE_TYPE.steam], 12, prop) },
            { [ENERGY_USAGE_TYPE.fuelOil]: this.getTableProp(tables[ENERGY_USAGE_TYPE.fuelOil], 12, prop) },
            { [ENERGY_USAGE_TYPE.propane]: this.getTableProp(tables[ENERGY_USAGE_TYPE.propane], 12, prop) },
            { eui: this.getTotalEUI(tables, squareFootage) },
            { square: squareFootage },
            { sqft: this.getTotalSquarePrice(tables, squareFootage) },
          ];
        }
      } catch (e) {
        console.warn('Cant fill Comparison Column', e);
      }
      return result;
    },
    getTableProp(table, index, prop) {
      return table && table[index] && table[index][prop];
    },
    getTotalKBTU(tables) {
      const result =
        Object.values(tables).reduce((accum, table) => {
          return accum + table[12].btu;
        }, 0) / 1000;
      return isNaN(result) ? null : parseFloat(result.toFixed(2));
    },
    getTotalEUI(tables, square) {
      let result = this.getTotalKBTU(tables) / square;
      if (isNaN(result)) {
        return null;
      }
      result = parseFloat(result.toFixed(2));
      return result === Infinity ? null : result;
    },
    getTotalSquarePrice(tables, square) {
      let result =
        Object.values(tables).reduce((accum, table) => {
          return accum + table[12].totalCost;
        }, 0) / square;
      if (isNaN(result)) {
        return null;
      }
      result = parseFloat(result.toFixed(2));
      return result === Infinity ? null : result;
    },
    getTotalFromEnergyTable(table, type, prop) {
      return table[type][12][prop] || 0;
    },
    getYearRange(planStartYear) {
      if (planStartYear) {
        return generateRangeArray(planStartYear - 20, planStartYear + 20).reverse();
      } else {
        return generateRangeArray(new Date().getFullYear() - 20, new Date().getFullYear() + 20).reverse();
      }
    },
    getProperty(item, key) {
      let cellValue = item?.[key];
      if (key === 'year') {
        return cellValue || '-';
      }
      if (typeof cellValue === 'number') {
        cellValue = parseFloat(cellValue.toFixed(2));
      }
      return cellValue?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '-';
    },
    getClientEnergy(facilityId, year) {
      let result = null;
      try {
        result = this.energyUsage[facilityId]
          ?.filter(item => item.year === year && item.ratesChart)
          .reduce((accum, energy) => {
            accum[energy.energyType] = JSON.parse(energy.ratesChart);
            return accum;
          }, {});
      } catch (e) {
        console.warn('Cant parse tables', e);
        result = null;
      }
      return result;
    },
    getTotalFromTable(table, index, prop) {
      return (table && table[index] && table[index][prop]) || 0;
    },
    addComparison() {
      ++this.clientComparison;
      this.headerData.push({ id: null, facilityId: null, year: null, facilities: [], years: [] });
      this.fillComparisonTableData();
    },
    removeComparison(index) {
      --this.clientComparison;
      this.headerData.splice(index, 1);
      this.fillComparisonTableData();
    },
    changeComparisonClient(index) {
      this.clientDropdownFilter = '';
      this.$apollo
        .query({
          query: CLIENT_QUERY,
          variables: { id: this.headerData[index].id },
          fetchPolicy: 'network-only',
        })
        .then(({ data: { client } }) => {
          if (client) {
            this.headerData[index].facilities = client.facilities || [];
            this.headerData[index].facilityId = null;
            this.headerData[index].years = this.getYearRange(+client.plans[0]?.financialInfo?.planStartYear || null);
            this.headerData[index].year = this.planStartYear;
            this.$nextTick(() => {
              this.fillComparisonTableData();
            });
          }
        });
    },
    async changeComparisonClientFacility(index) {
      if (this.headerData[index].facilityId) {
        this.energyUsage[this.headerData[index].facilityId] = await this.fetchEnergyUsage(this.headerData[index].facilityId);
        this.changeComparisonClientData(index);
      }
    },
    changeComparisonClientData(index) {
      if (this.headerData[index].facilityId && this.headerData[index].year) {
        let newElement = this.headerData[index];
        this.headerData.splice(index, 1, newElement);
        this.fillComparisonTableData();
      }
    },
    async fetchEnergyUsage(facilityId) {
      const response = await this.$apollo.query({
        query: ENERGY_USAGE_QUERY,
        variables: { facilityId: facilityId },
        fetchPolicy: 'network-only',
      });
      return response.data.energyUsage;
    },
    async onPrimaryFacilityChange() {
      this.energyUsage[this.clientComparisonData.facilityId] = await this.fetchEnergyUsage(this.clientComparisonData.facilityId);
      this.fillComparisonTableData();
    },
  },
};
