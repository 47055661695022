import CLIENT_QUERY from '@/graphql/queries/client.gql';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import Btn from '@/components/Btn/Btn.vue';
import ClientBreadcrumbs from '@/components/ClientBreadcrumbs/ClientBreadcrumbs.vue';
import ClientInfo from '@/components/ClientInfo/ClientInfo.vue';
import ComponentsReportModal from '@/components/ComponentsReportModal/ComponentsReportModal.vue';
import ExpendituresPlanning from '@/components/ExpendituresPlanning/ExpendituresPlanning.vue';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';

export default {
  name: 'Client',
  components: { ClientInfo, ClientBreadcrumbs, ComponentsReportModal, Btn, ExpendituresPlanning },
  props: { clientId: { type: Number | String } },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
    client: {
      query: CLIENT_QUERY,
      variables() {
        return { id: this.clientId };
      },
      error(e) {
        this.error = e;
      },
      fetchPolicy: 'network-only',
    },
  },
  data: () => ({
    client: null,
    currentUser: null,
    error: null,
  }),
  computed: {
    loading() {
      return this.$apollo.queries.client.loading;
    },
    plan() {
      return this.client?.plans ? this.client.plans[0] : null;
    },
    isReportButtonVisible() {
      return accessByPermissions(PERMISSIONS.GENERATE_REPORT, this.currentUser);
    },
  },
};
