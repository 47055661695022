var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"top":"","nudge-top":"-5","open-on-click":true,"max-width":"500px","open-on-hover":false,"color":"ics-light-grey-blue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('btn',_vm._b({directives:[{name:"click-outside",rawName:"v-click-outside",value:(
        function (e) {
          if (_vm.show) {
            _vm.show = false;
            on.click(e);
          }
        }
      ),expression:"\n        e => {\n          if (show) {\n            show = false;\n            on.click(e);\n          }\n        }\n      "}],attrs:{"icon":"","color-type":"secondary"},on:{"click":function (e) {
          _vm.show = !_vm.show;
          on.click(e);
        }}},'btn',attrs,false),[_c('v-icon',[_vm._v("fa-info-circle")])],1)]}}])},[_c('dl',{staticClass:"budget-info"},[_c('dd',[_vm._v("Base Amount")]),_c('dt',[_vm._v("= Quantity x Costs per unit x Regional cost%")]),_c('dd',[_vm._v("Component Subtotal")]),_c('dt',[_vm._v("= Base amount x Inflation escalation%")]),_c('dd',[_vm._v("Budget Amount")]),_c('dt',[_vm._v("= Component Subtotal x (1+ Soft costs % )")])])])}
var staticRenderFns = []

export { render, staticRenderFns }