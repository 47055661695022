import PrimaryNav from '@/components/PrimaryNav/PrimaryNav.vue';
export default {
  name: 'AppNavigationDrawer',
  components: { PrimaryNav },
  data() {
    return {
      drawer: true,
      mini: true,
      backendVersion: '1.0.0.0',
    };
  },
  computed: {
    frontendBuildNumber() {
      return process.env.VUE_APP_CURRENT_BUILD_NUMBER;
    },
    frontendVersion() {
      return `1.0.0.${this.frontendBuildNumber || 0}`;
    },
  },
  methods: {
    getBackendVersion() {
      fetch(process.env.VUE_APP_API_HTTP + '/version').then(async response => {
        if (response.ok) {
          const version = await response.text();
          version && (this.backendVersion = `1.0.0.${version || 0}`);
        }
      });
    },
  },
  created() {
    this.getBackendVersion();
  },
};
