import Btn from '@/components/Btn/Btn.vue';
export default {
  name: 'BudgetCalculationInfo',
  components: {
    Btn,
  },
  data: () => ({
    show: false,
  }),
};
