const PERMISSIONS = {
  VIEW_CLIENTS: 'view_clients',
  VIEW_CLIENT: 'view_client_details',
  ADD_CLIENT: 'add_client',
  EDIT_CLIENT: 'edit_client',
  DELETE_CLIENT: 'delete_client',
  ADD_CONTACT: 'add_contact',
  EDIT_CONTACT: 'edit_contact',
  DELETE_CONTACT: 'delete_contact',
  ADD_FACILITY: 'add_facility',
  EDIT_FACILITY: 'edit_facility',
  DELETE_FACILITY: 'delete_facility',
  VIEW_USERS: 'view_users',
  ADD_USER: 'add_user',
  EDIT_USER: 'edit_user',
  DELETE_USER: 'delete_user',
  CHANGE_USER_PERMISSIONS: 'change_user_permissions',
  VIEW_DATABASE: 'view_database',
  CREATE_SYSTEM: 'create_system',
  EDIT_SYSTEM: 'edit_system',
  DELETE_SYSTEM: 'delete_system',
  CREATE_DATABASE_COMPONENT: 'create_database_component',
  EDIT_DATABASE_COMPONENT: 'edit_database_component',
  DELETE_DATABASE_COMPONENT: 'delete_database_component',
  VIEW_PLANS: 'view_plans',
  VIEW_PLAN: 'view_plan_details',
  CREATE_PLAN: 'create_plan',
  EDIT_PLAN: 'edit_plan',
  DELETE_PLAN: 'delete_plan',
  CLONE_PLAN: 'clone_plan',
  ADD_PLAN_COMPONENT: 'add_plan_component',
  EDIT_PLAN_COMPONENT: 'edit_plan_component',
  DELETE_PLAN_COMPONENT: 'delete_plan_component',
  GENERATE_REPORT: 'generate_report',
  CHANGE_ROLE_PERMISSIONS: 'change_role_permissions',
  ADD_ADMIN: 'add_admin',
  VIEW_COMPLETED_COMPONENTS: 'view_completed_plan_components',
  VIEW_EXPENDITURE_OPTIONS: 'view_expenditure_options',
  DELETE_COMPLETED_COMPONENT: 'delete_completed_plan_component',
  EDIT_EXPENDITURE_OPTIONS: 'edit_expenditure_options',
  DELETE_EXPENDITURE_OPTIONS: 'delete_expenditure_options',
  MANAGE_DOCUMENTS: 'manage_documents',
  EDIT_FINANCIAL: 'edit_financial_info',
};
export const PERMISSION_LABELS = {
  [PERMISSIONS.VIEW_CLIENTS]: 'View Clients',
  [PERMISSIONS.VIEW_CLIENT]: 'View Client Details',
  [PERMISSIONS.ADD_CLIENT]: 'Add Client',
  [PERMISSIONS.EDIT_CLIENT]: 'Edit Client General Info',
  [PERMISSIONS.DELETE_CLIENT]: 'Delete Client',
  [PERMISSIONS.ADD_CONTACT]: 'Add Contact',
  [PERMISSIONS.EDIT_CONTACT]: 'Edit Contact',
  [PERMISSIONS.DELETE_CONTACT]: 'Delete Contact',
  [PERMISSIONS.ADD_FACILITY]: 'Add Facility',
  [PERMISSIONS.EDIT_FACILITY]: 'Edit Facility',
  [PERMISSIONS.DELETE_FACILITY]: 'Delete Facility',
  [PERMISSIONS.VIEW_USERS]: 'View Users',
  [PERMISSIONS.ADD_USER]: 'Add New User',
  [PERMISSIONS.EDIT_USER]: 'Edit User',
  [PERMISSIONS.DELETE_USER]: 'Delete User',
  [PERMISSIONS.CHANGE_USER_PERMISSIONS]: 'Change Permissions',
  [PERMISSIONS.VIEW_DATABASE]: 'View Database',
  [PERMISSIONS.CREATE_SYSTEM]: 'Create System',
  [PERMISSIONS.EDIT_SYSTEM]: 'Edit System',
  [PERMISSIONS.DELETE_SYSTEM]: 'Delete System',
  [PERMISSIONS.CREATE_DATABASE_COMPONENT]: 'Create Component',
  [PERMISSIONS.EDIT_DATABASE_COMPONENT]: 'Edit Component',
  [PERMISSIONS.DELETE_DATABASE_COMPONENT]: 'Delete Component',
  [PERMISSIONS.VIEW_PLANS]: 'View Plans',
  [PERMISSIONS.VIEW_PLAN]: 'View Plan',
  [PERMISSIONS.CREATE_PLAN]: 'Create Plan',
  [PERMISSIONS.EDIT_PLAN]: 'Edit Plan',
  [PERMISSIONS.DELETE_PLAN]: 'Delete Plan',
  [PERMISSIONS.CLONE_PLAN]: 'Clone Plan',
  [PERMISSIONS.GENERATE_REPORT]: 'Generate Report',
  [PERMISSIONS.ADD_PLAN_COMPONENT]: 'Add Component',
  [PERMISSIONS.EDIT_PLAN_COMPONENT]: 'Edit Component',
  [PERMISSIONS.DELETE_PLAN_COMPONENT]: 'Delete Component',
  [PERMISSIONS.CHANGE_ROLE_PERMISSIONS]: 'Change Permission for Roles',
  [PERMISSIONS.ADD_ADMIN]: 'Add Admin',
  [PERMISSIONS.DELETE_COMPLETED_COMPONENT]: 'Delete Completed Components',
  [PERMISSIONS.VIEW_EXPENDITURE_OPTIONS]: 'View Expenditure Options',
  [PERMISSIONS.DELETE_EXPENDITURE_OPTIONS]: 'Delete Expenditure Options',
  [PERMISSIONS.EDIT_EXPENDITURE_OPTIONS]: 'Edit Expenditure Options',
  [PERMISSIONS.MANAGE_DOCUMENTS]: 'Manage Documents',
  [PERMISSIONS.VIEW_COMPLETED_COMPONENTS]: 'View Completed Components',
  [PERMISSIONS.EDIT_FINANCIAL]: 'Edit Financial',
};

export const PERMISSION_GROUP_LABEL = {
  client: 'Client',
  manage_users: 'Manage Users',
  manage_database: 'Manage Database',
  roles: 'Settings',
};

export default PERMISSIONS;
