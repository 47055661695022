import { render, staticRenderFns } from "./ClientInfo.vue?vue&type=template&id=21e11b32&scoped=true&"
import script from "./ClientInfo.js?vue&type=script&lang=js&"
export * from "./ClientInfo.js?vue&type=script&lang=js&"
import style0 from "./ClientInfo.scss?vue&type=style&index=0&id=21e11b32&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21e11b32",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VOverlay,VProgressCircular,VRow,VTab,VTabItem,VTabs,VTabsItems})
