var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloQuery',{staticClass:"clients-container",attrs:{"query":_vm.CLIENTS_QUERY,"fetch-policy":'network-only'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var loading = ref_result.loading;
var error = ref_result.error;
var data = ref_result.data;
return [_c('alert',{attrs:{"error":error}}),(_vm.showGenerateReportsBtn)?_c('div',{staticClass:"report-buttons"},[(!_vm.showGenerateReportBtn)?_c('btn',{attrs:{"width":"200","color-type":"accent","disabled":loading},on:{"click":_vm.onCompare}},[_vm._v("Compare Clients ")]):[_c('btn',{attrs:{"width":"150","color-type":"accent","disabled":loading},on:{"click":_vm.onReset}},[_vm._v("Reset Selected")]),_c('modal',{attrs:{"title":"Compare Clients","width":"440px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('btn',_vm._g(_vm._b({staticClass:"ml-6",attrs:{"width":"200","color-type":"accent","disabled":loading || _vm.selected.length === 0}},'btn',attrs,false),on),[_vm._v("Comparison Report")])]}},{key:"default",fn:function(ref){
var close = ref.close;
return [_c('plans-report',{attrs:{"plans":_vm.selectedPlans},on:{"done":close}})]}}],null,true)})]],2):_vm._e(),_c('client-list',{attrs:{"clients":(data && data.clients) || [],"loading":loading,"selected":_vm.selected,"show-select":_vm.showGenerateReportBtn},on:{"select":_vm.onSelect}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }