import DataTable from '@/components/DataTable/DataTable.vue';
import { generateRangeArray } from '@/utils/generator';
import currencyFilter from '@/filters/currency';

const reportTypes = {
  itemizedListOfFacilityCosts: 'itemizedListOfFacilityCosts',
  summaryByFiscalYear: 'summaryByFiscalYear',
  detailedSummaryOfFacilityCosts: 'detailedSummaryOfFacilityCosts',
  summaryByFacility: 'summaryByFacility',
  summaryByFundingSource: 'summaryByFundingSource',
  expenditureSpreadsheet: 'expenditureSpreadsheet',
};

export default {
  name: 'ReportDetails',
  props: {
    items: {
      type: Array | Object,
      default: null,
    },
    type: {
      type: String,
      default: '',
    },
    startYear: {
      type: Number,
      default: 1970,
    },
    endYear: {
      type: Number,
      default: 1970,
    },
    loading: { type: Boolean },
  },
  components: { DataTable },
  data: () => ({ reportTypes }),
  computed: {
    simpleTableItems() {
      const result = this[reportTypes[this.type] + 'Items'];
      return result || null;
    },
    simpleTableHeaders() {
      const result = this[reportTypes[this.type] + 'Headers'];
      return result || null;
    },
    years() {
      return generateRangeArray(this.startYear, this.endYear);
    },
    expenditureSpreadsheetItems() {
      if (!this.items || this.type !== reportTypes.expenditureSpreadsheet) {
        return null;
      }
      const less100KHealthDataRows = this.getExpenditureTableSection(this.items.less100KHealthData, 'health');
      if (less100KHealthDataRows.length) {
        less100KHealthDataRows.unshift({
          heading: 'Health and Safety, IAQ and Abatement Projects',
          key: 'health_span',
        });
      }
      const more100KHealthDataRows = this.getExpenditureTableSection(this.items.more100KHealthData, 'iaq');
      if (more100KHealthDataRows.length) {
        more100KHealthDataRows.unshift({
          heading: 'IAQ, Fire and Abatement, Projects Costing > $100,000 per Site',
          key: 'iaq_span',
        });
      }
      const deferredDataRows = this.getExpenditureTableSection(this.items.deferredData, 'deferred');
      if (deferredDataRows.length) {
        deferredDataRows.unshift({
          heading: 'Deferred Capital Expenditures and Maintenance Projects',
          key: 'deferred_span',
        });
      }
      //Total Annual 10 Year Plan Expenditures
      const totalAnnual = {
        key: 'annual',
        system: { value: '<b>Total Annual 10 Year Plan Expenditures</b>' },
        code: { value: ' ' },
      };
      const grandTotal = {
        key: 'grand-total',
        system: { value: '<b>Total 10 Year Plan Expenditures</b>' },
        code: { value: ' ' },
      };
      this.years.forEach(year => {
        totalAnnual[year] = { value: 0 };
        grandTotal[year] = { value: 0 };
      });
      this.years.forEach(year => {
        if (less100KHealthDataRows.length) {
          totalAnnual[year].value += less100KHealthDataRows[less100KHealthDataRows.length - 1][year]?.value || 0;
        }
        if (more100KHealthDataRows.length) {
          totalAnnual[year].value += more100KHealthDataRows[more100KHealthDataRows.length - 1][year]?.value || 0;
        }
        if (deferredDataRows.length) {
          totalAnnual[year].value += deferredDataRows[deferredDataRows.length - 1][year]?.value || 0;
        }
        grandTotal[this.years[0]].value += totalAnnual[year].value;
      });

      return [{ header: true }, ...less100KHealthDataRows, ...more100KHealthDataRows, ...deferredDataRows, ...[totalAnnual, grandTotal]];
    },
    expenditureSpreadsheetHeaders() {
      const years = this.years.map(year => ({ text: year, value: year, width: '96px' }));
      return [
        {
          text: '',
          value: 'code',
          width: '92px',
        },
        {
          text: '',
          value: 'system',
          width: '284px',
        },
        ...years,
      ];
    },
    itemizedListOfFacilityCostsHeaders() {
      return [
        {
          text: 'Facility',
          value: 'facility',
          width: '100px',
        },
        {
          text: 'System',
          value: 'system',
          width: '100px',
        },
        {
          text: 'Component',
          value: 'component',
          width: '100px',
        },
        {
          text: 'Improvement Year',
          value: 'yearForImprovement',
          width: '100px',
        },
        {
          text: 'Quantity',
          value: 'quantityOfComponents',
          width: '100px',
        },
        {
          text: 'Unit',
          value: 'unit',
          width: '100px',
        },
        {
          text: 'Costs per unit',
          value: 'costPerUnit',
          width: '100px',
        },
        {
          text: 'Regional Cost',
          value: 'regionalCost',
          width: '100px',
        },
        {
          text: 'Base Amount',
          value: 'baseAmount',
          width: '100px',
        },
        {
          text: 'Component Subtotal',
          value: 'componentSubtotal',
          width: '100px',
        },
        {
          text: 'Soft Cost',
          value: 'softCost',
          width: '100px',
        },
        {
          text: 'Budget Amount',
          value: 'budgetAmount',
          width: '100px',
        },
        {
          text: 'Budgetary Notes',
          value: 'budgetaryNotes',
          width: '300px',
        },
        {
          text: 'Description',
          value: 'description',
          width: '300px',
        },
        {
          text: 'Average Lifecycle',
          value: 'averageLifeCycle',
          width: '100px',
        },
        {
          text: 'Age',
          value: 'componentAge',
          width: '100px',
        },
        {
          text: 'Condition',
          value: 'conditionAssessment',
          width: '100px',
        },
        {
          text: 'Priority',
          value: 'priority',
          width: '100px',
        },
        {
          text: 'Location',
          value: 'locationOfComponent',
          width: '100px',
        },
        {
          text: 'Funding source',
          value: 'fundingSource',
          width: '100px',
        },
        {
          text: 'Internal Notes',
          value: 'internalNotes',
          width: '100px',
        },
      ];
    },
    itemizedListOfFacilityCostsItems() {
      if (this.type !== reportTypes.itemizedListOfFacilityCosts) {
        return null;
      }
      return this.items?.reportData.map(item => {
        item.key = item.id;
        return item;
      });
    },
    summaryByFiscalYearHeaders() {
      const years = this.years.map(year => ({ text: year, value: year, width: '96px' }));
      return [
        {
          text: 'System',
          value: 'system',
          width: '284px',
        },
        ...years,
        {
          text: 'Grand Total',
          value: 'grandTotal',
          width: '96px',
        },
      ];
    },
    summaryByFiscalYearItems() {
      if (this.type !== reportTypes.summaryByFiscalYear) {
        return null;
      }
      const totals = {
        grandTotal: 0,
      };
      this.years.forEach(year => {
        totals[year] = 0;
      });
      const data = this.items?.groupData.map(item => {
        const row = {};
        row.system = item.system;
        row.key = item.system;
        let rowTotal = 0;
        this.years.forEach(year => {
          row[year] = this.currencyLayout({ value: item[year] });
          totals[year] += item[year] || 0;
          rowTotal += item[year] || 0;
        });
        row['grandTotal'] = `<b>${this.currencyLayout({ value: rowTotal })}</b>`;
        totals['grandTotal'] += rowTotal;
        return row;
      });
      for (let year in totals) {
        totals[year] = `<b>${this.currencyLayout({ value: totals[year] })}</b>`;
      }
      if (data.length) {
        data.push({
          key: 'total',
          system: '<b>Grand Total</b>',
          ...totals,
        });
      }
      return data;
    },
    detailedSummaryOfFacilityCostsHeaders() {
      const years = this.years.map(year => ({ text: year, value: year, width: '96px' }));
      return [
        {
          text: 'Facility',
          value: 'facility',
          width: '112px',
        },
        {
          text: 'System',
          value: 'system',
          width: '160px',
        },
        {
          text: 'Component',
          value: 'component',
          width: '160px',
        },
        ...years,
      ];
    },
    detailedSummaryOfFacilityCostsItems() {
      if (this.type !== reportTypes.detailedSummaryOfFacilityCosts) {
        return null;
      }
      const uniqFacility = new Set();
      const totals = {};
      this.years.forEach(year => {
        totals[year] = 0;
      });
      const data = this.items?.sortArray.map((item, index) => {
        const row = {};
        row.key = item.system + index;
        row.system = item.system;
        row.facility = uniqFacility.has(item.facility) ? '' : uniqFacility.add(item.facility) && item.facility;
        row.component = item.component;
        this.years.forEach(year => {
          row[year] = this.currencyLayout({ value: item[year] });
          totals[year] += item[year];
        });
        return row;
      });
      for (let year in totals) {
        totals[year] = `<b>${this.currencyLayout({ value: totals[year] })}</b>`;
      }
      if (data.length) {
        data.push({
          key: 'total',
          facility: '<b>Total</b>',
          system: ' ',
          component: ' ',
          ...totals,
        });
      }
      return data;
    },
    summaryByFacilityHeaders() {
      if (!this.items || this.type !== reportTypes.summaryByFacility) {
        return null;
      }
      const headers = this.items.headerNames.slice(2).map(name => ({
        text: name,
        value: name,
        width: '160px',
      }));
      return [
        {
          text: '',
          value: 'type',
          width: '160px',
        },
        {
          text: 'System',
          value: 'system',
          width: '280px',
        },
        ...headers,
      ];
    },
    summaryByFacilityItems() {
      if (!this.items || this.type !== reportTypes.summaryByFacility) {
        return null;
      }
      const facilities = this.items.headerNames.slice(2, -1);
      const less100KHealthDataRows = this.getSummaryByFacilitySection(this.items.less100KHealthData, facilities, 'Health and Safety');
      const more100KHealthDataRows = this.getSummaryByFacilitySection(this.items.more100KHealthData, facilities, 'IAQ, Fire and Abatement');
      const deferredDataRows = this.getSummaryByFacilitySection(this.items.deferredData, facilities, 'Deferred');
      const grandTotal = {
        type: '<b>Grand Total</b>',
        key: 'grand_total',
        system: '',
      };
      facilities.concat(['Grand Total']).forEach(facilityName => {
        grandTotal[facilityName] = 0;
        if (deferredDataRows.length) {
          const row = deferredDataRows[deferredDataRows.length - 1];
          grandTotal[facilityName] += row[facilityName] || 0;
        }
        if (less100KHealthDataRows.length) {
          const row = less100KHealthDataRows[less100KHealthDataRows.length - 1];
          grandTotal[facilityName] += row[facilityName] || 0;
        }
        if (more100KHealthDataRows.length) {
          const row = more100KHealthDataRows[more100KHealthDataRows.length - 1];
          grandTotal[facilityName] += row[facilityName] || 0;
        }
      });
      const result = [...deferredDataRows, ...less100KHealthDataRows, ...more100KHealthDataRows, ...[grandTotal]];
      result.forEach(row => {
        facilities.forEach(facilityName => {
          if (row.type?.includes('Total')) {
            row[facilityName] = `<b>${this.currencyLayout({ value: row[facilityName] })}</b>`;
          } else {
            row[facilityName] = this.currencyLayout({ value: row[facilityName] });
          }
        });
        row['Grand Total'] = `<b>${this.currencyLayout({ value: row['Grand Total'] })}</b>`;
      });
      return result;
    },
    summaryByFundingSourceHeaders() {
      const years = this.years.map((year, index) => ({ text: year, value: index + 1, width: '96px' }));
      return [
        {
          text: '',
          value: 'type',
          width: '160px',
        },
        ...years,
      ];
    },
    summaryByFundingSourceItems() {
      if (this.type !== reportTypes.summaryByFundingSource) {
        return null;
      }
      const years = this.years.map((year, index) => index + 1);
      const totalBallance = { type: 'Total Starting Balance', key: 'totalBallance' };
      const totalRevenue = { type: 'Total Anticipated Revenue', key: 'totalRevenue' };
      const totalExpenses = { type: 'Total Anticipated Expenses', key: 'totalExpenses' };
      const totalEoy = { type: 'Total Projected EOY Balance', key: 'totalEoy' };
      years.forEach(year => {
        totalBallance[year] = 0;
        totalRevenue[year] = 0;
        totalExpenses[year] = 0;
        totalEoy[year] = 0;
      });
      const result = [];
      if (this.items?.groupData) {
        const groupData = this.items?.groupData;
        for (let i = 0; i < this.items?.groupData.length; i += 5) {
          const sourceTitle = groupData[i][0];
          const titleRow = {
            type: `<b>${sourceTitle}<b>`,
            key: sourceTitle,
          };
          const ballanceRow = { type: groupData[i + 1][0], key: sourceTitle + 'ballance' };
          const revenueRow = { type: groupData[i + 2][0], key: sourceTitle + 'revenue' };
          const expensesRow = { type: groupData[i + 3][0], key: sourceTitle + 'expenses' };
          const eoyRow = { type: groupData[i + 4][0], key: sourceTitle + 'eoy' };
          years.forEach(year => {
            ballanceRow[year] = this.currencyLayout({ value: groupData[i + 1][year] });
            revenueRow[year] = this.currencyLayout({ value: groupData[i + 2][year] });
            expensesRow[year] = this.currencyLayout({ value: groupData[i + 3][year] });
            eoyRow[year] = this.currencyLayout({ value: groupData[i + 4][year] });

            totalBallance[year] += groupData[i + 1][year];
            totalRevenue[year] += groupData[i + 2][year];
            totalExpenses[year] += groupData[i + 3][year];
            totalEoy[year] += groupData[i + 4][year];
          });
          result.push(titleRow, ballanceRow, revenueRow, expensesRow, eoyRow);
        }
        if (result.length) {
          for (let keyName in totalBallance) {
            totalBallance[keyName] = `<b>${this.currencyLayout({ value: totalBallance[keyName] })}</b>`;
            totalRevenue[keyName] = `<b>${this.currencyLayout({ value: totalRevenue[keyName] })}</b>`;
            totalExpenses[keyName] = `<b>${this.currencyLayout({ value: totalExpenses[keyName] })}</b>`;
            totalEoy[keyName] = `<b>${this.currencyLayout({ value: totalEoy[keyName] })}</b>`;
          }
          result.push(totalBallance, totalRevenue, totalExpenses, totalEoy);
        }
      }

      // const data = this.items?.groupData.map((item, index) => {
      //   const row = {};
      //   if (item.length === 1) {
      //     row.type = `<b>${item[0]}<b>`;
      //   } else {
      //     row.type = item[0];
      //     years.forEach(year => {
      //       row[year] = this.currencyLayout({ value: item[year] });
      //     });
      //   }
      //   row.key = item[0] + index;

      //   return row;
      // });
      return result;
    },
  },
  methods: {
    getExpenditureTableSection(section, sectionName) {
      const totals = {};
      this.years.forEach(year => {
        totals[year] = { value: 0 };
      });
      const result = section.map(item => {
        const row = {};
        row.code = { value: item.finCode?.toString() || null };
        row.system = { value: item.system };
        row.key = item.system + sectionName;
        this.years.forEach(year => {
          row[year] = { value: item[year] };
          totals[year].value += item[year] || 0;
        });
        return row;
      });
      if (result.length) {
        result.push({
          code: { value: ' ' },
          system: { value: '<b>Total</b>' },
          key: 'total_' + sectionName,
          ...totals,
        });
      }
      return result;
    },
    getSummaryByFacilitySection(section, facilities, label) {
      const totals = {
        'Grand Total': 0,
      };
      facilities.forEach(facilityName => {
        totals[facilityName] = 0;
      });
      const result = section.map(item => {
        const row = {};
        row.system = item.system;
        row.key = item.system;
        let rowTotal = 0;
        facilities.forEach(facilityName => {
          row[facilityName] = item[facilityName];
          totals[facilityName] += item[facilityName] || 0;
          rowTotal += item[facilityName] || 0;
        });
        row['Grand Total'] = rowTotal;
        totals['Grand Total'] += rowTotal;
        return row;
      });
      if (result.length) {
        result[0].type = `<b>${label}</b>`;
        result.push({
          type: `<b>${label} Total</b>`,
          key: label + ' Total',
          system: '',
          ...totals,
        });
      }
      return result;
    },
    currencyLayout(cell) {
      if (!cell.value) return '-';
      if (typeof cell.value !== 'number') return cell.value;
      if (cell.value < 0) {
        return `<span class="negative-budget">(${currencyFilter(cell.value * -1)})</span>`;
      }
      return currencyFilter(cell.value);
    },
    getSlotName(header) {
      return 'item.' + header.value;
    },
  },
};
