import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';

export default {
  name: 'Card',
  props: {
    title: {
      type: String,
      default: 'Title HEre',
    },
    subtitle: {
      type: String,
      default: 'Subtitle here very long',
    },
    items: {
      type: Array,
      default: () => [],
    },
    loading: { type: Boolean },
    columnCount: {
      type: String | Number,
      default: 1,
    },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
  },
  data: () => ({
    fileList: [],
    internalLoading: false,
  }),
};
