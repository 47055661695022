import Btn from '@/components/Btn/Btn.vue';
import Modal from '@/components/Modal/Modal.vue';
import AddFacility from './AddFacility/AddFacility.vue';
import FacilityList from '@/components/FacilityList/FacilityList.vue';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';

export default {
  name: 'Facilities',
  components: { Btn, Modal, AddFacility, FacilityList },
  props: {
    client: {
      type: Object,
      default: null,
    },
    loading: { type: Boolean },
    showStepButtons: { type: Boolean, default: true },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
  },
  data: () => ({
    currentUser: null,
  }),
  computed: {
    showAddFacilityBtn() {
      return accessByPermissions(PERMISSIONS.ADD_FACILITY, this.currentUser);
    },
  },
  methods: {
    onNextClick() {
      this.$emit('done');
    },
    onBackClick() {
      this.$emit('back');
    },
  },
};
