import Btn from '@/components/Btn/Btn.vue';
import Modal from '@/components/Modal/Modal.vue';
import eventHub from '@/utils/eventHub';
import { download } from '@/utils/file';

export default {
  name: 'ReportNameModal',
  components: { Modal, Btn },
  props: {
    btnName: {
      type: String,
      default: 'Generate',
    },
    btnTitle: {
      type: String,
      default: 'Generate Report',
    },
    modalTitle: {
      type: String,
      default: 'Generate Report',
    },
    disabled: false,
    variables: {
      type: Object,
    },
    mutation: null,
    onMutationCallback: null,
  },
  data: () => ({
    name: '',
    nameRules: [v => !!v || 'Name is Required'],
    submitFormData: false,
  }),
  computed: {
    isNameValid() {
      return this.name === null || this.name === '';
    },
  },
  methods: {
    downloadFile(_, { data: { generateReports } }) {
      this.submitFormData = false;
      if (generateReports) {
        download(generateReports.url, this.name);
        eventHub.$emit('show-snackbar', {
          color: 'success',
          text: `Reports were generated successfully!`,
        });
        this.$emit('done');
      } else {
        eventHub.$emit('show-snackbar', {
          color: 'error',
          text: `Error! Something went wrong... Please try later!`,
        });
      }
    },
  },
};
