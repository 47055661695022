import Login from '@/views/Login/Login.vue';
import ClientList from '@/views/ClientList/ClientList.vue';
import ClientDetails from '@/views/ClientDetails.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import PERMISSIONS from '@/enums/permissions';

const ROUTES = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'ICS - Login',
      pageTitle: 'Login',
      requiresAuth: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: {
      title: 'ICS - Reset Password',
      pageTitle: 'Reset Password',
      requiresAuth: false,
    },
  },
  {
    path: '/clients',
    name: 'clients',
    meta: {
      title: 'ICS - Clients',
      pageTitle: 'Clients',
      permission: PERMISSIONS.VIEW_CLIENTS,
    },
    component: ClientList,
  },
  {
    path: '/client-details/:clientId',
    name: 'client-details',
    meta: {
      title: 'ICS - Client Details',
      permission: PERMISSIONS.VIEW_CLIENT,
    },
    component: ClientDetails,
  },
  {
    path: '/manage-users',
    name: 'manage-users',
    meta: {
      title: 'ICS - Manage Users',
      pageTitle: 'Manage Users',
      permission: PERMISSIONS.VIEW_USERS,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "manage-users" */ '../views/ManageUsers/ManageUsers.vue'),
  },
  {
    path: '/manage-database',
    name: 'manage-database',
    meta: {
      title: 'ICS - Manage Database',
      pageTitle: 'Manage Database',
      permission: PERMISSIONS.VIEW_DATABASE,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "manage-database" */ '../views/ManageDatabase/ManageDatabase.vue'),
  },
  {
    path: '/settings',
    name: 'settings',
    meta: {
      title: 'ICS - Setting',
      pageTitle: 'Settings',
      permission: PERMISSIONS.CHANGE_ROLE_PERMISSIONS,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
  },
];

export default ROUTES;
