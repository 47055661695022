var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-table',{staticClass:"facility-list",attrs:{"items":_vm.displayedItems,"headers":_vm.displayedHeaders,"height":"611px","item-key":"id","loading":_vm.loading,"noDataText":"No Facilities"},scopedSlots:_vm._u([{key:"item.urlPhotos",fn:function(ref){
var item = ref.item;
return [(item.urlPhotos)?_c('a',{attrs:{"href":item.urlPhotos,"target":"_blank"}},[_vm._v("Link to Photos")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-actions-bar"},[_c('modal',{attrs:{"title":"Edit Facility","width":"812px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.showEditBtn)?_c('v-icon',_vm._g(_vm._b({staticClass:"action edit mr-2",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("far fa-edit")]):_vm._e()]}},{key:"default",fn:function(ref){
var close = ref.close;
return [_c('edit-facility',{attrs:{"client":_vm.client,"data":item},on:{"done":close}})]}}],null,true)}),(_vm.showDeleteBtn)?_c('confirmation-modal',{attrs:{"mutation":_vm.DELETE_FACILITY_MUTATION,"variables":{ id: item.id },"title":"Delete Facility","ok-btn":{ label: 'Delete', loading: _vm.loadingConfirmation },"update":_vm.onDelete(item)},on:{"close":_vm.onModalClose},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('ApolloQuery',{attrs:{"query":_vm.COMPONENTS_OF_NEED_BY_FACILITY,"variables":{ facilityId: item.id },"update":_vm.onQueryFinish},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [(_vm.loadingConfirmation)?_c('div',{staticClass:"loader-block"},[_c('div',{staticClass:"loader mt-4"})]):[(_vm.confirmResult.componentsOfNeedByFacility.length > 0)?_c('p',{staticClass:"alert-msg"},[_vm._v(" The client added components to the facility. "),_c('br'),_vm._v("Deleting the facility will delete components. ")]):_vm._e(),_c('p',[_vm._v("Are you sure you want to delete facility?")])]]}}],null,true)})]},proxy:true},{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action",attrs:{"small":""}},'v-icon',{ attrs: attrs },false),on),[_vm._v(" far fa-trash-alt ")])]}}],null,true)}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }