import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import PERMISSIONS from '@/enums/permissions';

export default {
  name: 'ClientBreadcrumbs',
  components: { Breadcrumbs },
  props: { client: { type: Object }, isEdit: { type: Boolean, default: false } },
  computed: {
    items() {
      return [
        {
          text: 'Clients',
          to: { name: 'clients' },
          permission: PERMISSIONS.VIEW_CLIENTS,
        },
        ...(this.client
          ? [
              {
                text: this.client.name || '',
                href: '/client-details/' + this.client.id,
                disabled: !this.isEdit,
              },
            ]
          : []),
        ...(this.isEdit
          ? [
              {
                text: 'Edit Client',
                disabled: true,
              },
            ]
          : []),
      ];
    },
  },
};
