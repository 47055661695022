import DataTable from '@/components/DataTable/DataTable.vue';
import { generateRangeArray } from '@/utils/generator';
import { calculateBudget, getClientBudget } from '@/services/componentBudget';
import { cloneDeep } from 'lodash';
import currencyFilter from '@/filters/currency';

export default {
  name: 'BudgetTable',
  components: { DataTable },
  props: {
    plan: { type: Object, default: null },
    components: { type: Array, default: [] },
    loading: { type: Boolean },
    changes: { type: Array },
    customBudgetCells: {
      type: Object,
      default() {
        return {
          revenue: {},
          startingBallance: {},
        };
      },
    },
  },
  data: vm => {
    const startYear = vm.plan && vm.plan.financialInfo && parseInt(vm.plan.financialInfo.planStartYear);
    const years = startYear ? generateRangeArray(startYear, startYear + 19) : [];
    const yearCols = years.map(y => ({
      text: y,
      value: y,
      sortable: false,
      width: '124px',
    }));
    const fakeEmptyRow = years.reduce((prev, current) => {
      prev[current] = { value: '' };
      return prev;
    }, {});
    return {
      fakeEmptyRow,
      fundingSourceModel: [],
      years: years,
      displayedHeaders: [
        {
          text: '',
          name: '',
          value: 'label',
          sortable: false,
          fixed: true,
          width: '232px',
          slot: true,
        },
        ...yearCols,
      ],
      items: [],
      tableHeader: null,
      tableWrapper: null,
      tableRects: null,
    };
  },
  watch: {
    //select all funding sources in dropdown by default
    fundingSources: {
      handler() {
        this.fundingSourceModel = this.fundingSources.map(item => item.name);
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateTableRects);
  },
  mounted() {
    setTimeout(() => {
      this.initializeFloatingHeader();
    }, 200);
  },
  computed: {
    componentsByYearMap() {
      const mapByYears = new Map();
      this.years.forEach(year =>
        mapByYears.set(
          year,
          this.components.filter(component => component.yearForImprovement === year)
        )
      );
      return mapByYears;
    },
    financialInfo() {
      return this?.plan?.financialInfo || {};
    },
    fundingSources() {
      return this?.plan?.financialInfo?.fundingSources || [];
    },
    selectedFundingSources() {
      return this.fundingSourceModel.map(item => this.fundingSources.find(source => source.name === item));
    },
    displayedItems() {
      const result = [];

      // fill Total Lines
      const totalStartingBallanceRow = {};
      const totalRevenueRow = {};
      const totalExpensesRow = {};
      const totalProjectedBallanceRow = {};
      this.years.forEach(year => {
        totalStartingBallanceRow[year] = { value: 0, className: 'total-cell bolded-cell' };
        totalRevenueRow[year] = { value: 0, className: 'total-cell bolded-cell' };
        totalExpensesRow[year] = { value: 0, className: 'total-cell bolded-cell' };
        totalProjectedBallanceRow[year] = { value: 0, className: 'total-cell bolded-cell' };
      });

      this.selectedFundingSources.forEach((fundingSource, index) => {
        const componentsByFoundingSourceMap = new Map();
        this.componentsByYearMap.forEach((components, year) => {
          componentsByFoundingSourceMap.set(
            year,
            components.filter(component => component.fundingSource?.id === fundingSource.id)
          );
        });
        const startingBallanceRow = {};
        const revenueRow = {};
        const expensesRow = {};
        const ballanceRow = {};
        let prevProjectedBallance = 0;
        this.years.forEach(year => {
          const customStartingBallance = this.getCustomCellValue(year, fundingSource, this.customBudgetCells.startingBallance);
          const startingBallance = customStartingBallance ?? 0 + prevProjectedBallance;
          const customRevenue = this.getCustomCellValue(year, fundingSource, this.customBudgetCells.revenue);
          const revenue = customRevenue ?? this.generateRevenue(year, fundingSource.id);
          const expenses = this.generateExpenses(year, componentsByFoundingSourceMap);
          const projectedBallance = (prevProjectedBallance = startingBallance + revenue - expenses);
          startingBallanceRow[year] = { value: startingBallance };
          revenueRow[year] = { value: revenue };
          expensesRow[year] = { value: expenses };
          ballanceRow[year] = { value: projectedBallance, className: 'eoy-ballance-cell' };

          totalStartingBallanceRow[year].value += startingBallance;
          totalRevenueRow[year].value += revenue;
          totalExpensesRow[year].value += expenses;
          totalProjectedBallanceRow[year].value += projectedBallance;
        });

        result.push(
          { ...this.fakeEmptyRow, label: fundingSource.name, thClass: 'bolded-cell', key: index, fundingSource: fundingSource },
          { ...startingBallanceRow, label: 'Starting Balance', key: 'st_' + index, editable: true, fundingSource: fundingSource },
          { ...revenueRow, label: 'Anticipated Revenue', key: 'ar_' + index, editable: true, fundingSource: fundingSource },
          { ...expensesRow, label: 'Anticipated Expenses', key: 'ae_' + index, fundingSource: fundingSource },
          { ...ballanceRow, label: 'Projected EOY Balance', key: 'pb_' + index, thClass: 'eoy-ballance-cell', fundingSource: fundingSource }
        );
      });

      const totals = [
        { ...totalStartingBallanceRow, label: 'Total Starting Balance', thClass: 'total-cell bolded-cell', key: 'total_starting' },
        { ...totalRevenueRow, label: 'Total Anticipated Revenue', thClass: 'total-cell bolded-cell', key: 'total_revenue' },
        { ...totalExpensesRow, label: 'Total Anticipated Expenses', thClass: 'total-cell bolded-cell', key: 'total_expenses' },
        { ...totalProjectedBallanceRow, label: 'Total Projected EOY Balance', thClass: 'total-cell bolded-cell', key: 'total_ballance' },
      ];
      return result.concat(totals);
    },
  },
  methods: {
    currencyLayout(row, cell) {
      if (cell.value < 0) {
        return `<span class="negative-budget">(${currencyFilter(cell.value * -1)})</span>`;
      }
      if (row.label === 'Anticipated Expenses') {
        return `(${currencyFilter(cell.value)})`;
      }
      return currencyFilter(cell.value);
    },
    getCustomCellValue(year, fundingSource, customList) {
      const row = customList[fundingSource.id];
      return (row && row[year]) ?? null;
    },
    getFundingData(fundingSourceId) {
      const fundingSource = this.financialInfo.fundingSources.find(source => source.id === fundingSourceId);
      return {
        capitalFundingAmount: fundingSource?.capitalFundingAmount || 0,
        fundingEscalation: fundingSource?.fundingEscalation || 0,
        capitalInfusions: fundingSource?.capitalInfusions || [],
      };
    },
    generateRevenue(year, fundingSourceId) {
      const { capitalFundingAmount, fundingEscalation, capitalInfusions } = this.getFundingData(fundingSourceId);
      const budget = fundingEscalation
        ? getClientBudget(capitalFundingAmount, fundingEscalation, year, this.financialInfo.planStartYear)
        : capitalFundingAmount;
      const currentCapitalInfusion = capitalInfusions.find(infusion => infusion.capitalInfusionYear === year);
      const capitalInfusionAmount = parseFloat(currentCapitalInfusion?.capitalInfusionAmount) || 0;
      return budget + capitalInfusionAmount;
    },
    generateExpenses(year, componentsByFoundingSourceMap) {
      const componentByYear = componentsByFoundingSourceMap.get(year);
      const budget = componentByYear.reduce((prevComponentBudget, currentComponent) => {
        const { budgetAmount } = calculateBudget(currentComponent, this.plan);
        return prevComponentBudget + budgetAmount;
      }, 0);
      return budget;
    },
    initializeFloatingHeader() {
      this.tableHeader = this.$refs.budget_table.$el.querySelector('tr.header');
      this.tableWrapper = this.$refs.budget_table.$el.querySelector('.v-data-table__wrapper');
      this.tableRects = this.$refs.budget_table.$el.getBoundingClientRect();
      window.addEventListener('resize', this.updateTableRects);
      document.getElementById('budget-planning-wrapper').parentNode.addEventListener('scroll', this.onScroll);
      this.tableWrapper.addEventListener('scroll', this.onLeftScroll);
    },
    onFundingChange() {
      this.$emit(
        'fundingChange',
        this.selectedFundingSources.map(item => item.id)
      );
      this.$nextTick(() => {
        this.updateTableRects();
      });
    },
    onInputChange(event, year, row) {
      const value = event.currentTarget.value.toString().replace(/[^0-9.]/g, '');
      if (value === '') {
        this.restCustomCellValue(year, row.fundingSource, row.label);
      } else {
        this.setCustomCellValue(year, row.fundingSource, row.label, parseFloat(parseFloat(value).toFixed(2)) || 0);
      }
      event.currentTarget.parentNode.classList.remove('-hide-budget-table-input-label');
    },
    onLeftScroll(e) {
      this.tableHeader.scrollTo(e.target.scrollLeft, 0);
    },
    onScroll(e) {
      if (this.$refs.budget_table) {
        e.target.click();
        const startPoint = 70;
        const scrollTop = e.target.scrollTop;
        const tableEndLine = this.tableRects.height + startPoint;
        const table = this.$refs.budget_table.$el;
        if (scrollTop > startPoint && scrollTop < tableEndLine) {
          this.tableHeader.style.width = this.tableRects.width + 'px';
          table.classList.add('-float-budget-header');
        } else {
          table.classList.remove('-float-budget-header');
          this.tableHeader.style.width = '100%';
        }
      }
    },
    restCustomCellValue(year, fundingSource, rowLabel) {
      const key = rowLabel === 'Anticipated Revenue' ? 'revenue' : 'startingBallance';
      const cells = cloneDeep(this.customBudgetCells);
      cells[key][fundingSource.id] && delete cells[key][fundingSource.id][year];
      if (!Object.values(cells[key][fundingSource.id]).length) {
        delete cells[key][fundingSource.id];
      }
      this.$emit('update:customBudgetCells', cells);
    },
    setCustomCellValue(year, fundingSource, rowLabel, value) {
      const cells = cloneDeep(this.customBudgetCells);
      const key = rowLabel === 'Anticipated Revenue' ? 'revenue' : 'startingBallance';
      if (!cells[key][fundingSource.id] || typeof cells[key][fundingSource.id] !== 'object') {
        cells[key][fundingSource.id] = { [year]: value };
      } else {
        cells[key][fundingSource.id][year] = value;
      }
      this.$emit('update:customBudgetCells', cells);
    },
    showInput(event) {
      const parent = event.currentTarget.parentNode;
      parent.classList.add('-hide-budget-table-input-label');
      parent.querySelector('input').focus();
    },
    hideInput(event) {
      event.currentTarget.parentNode.classList.remove('-hide-budget-table-input-label');
    },
    updateTableRects() {
      this.tableRects = this.$refs.budget_table.$el.getBoundingClientRect();
    },
  },
};
