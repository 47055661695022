var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloMutation',_vm._g({staticClass:"add-contact-form",attrs:{"mutation":_vm.ADD_CONTACT_MUTATION,"variables":{
    input: Object.assign({}, _vm.contact,
      {phones: _vm.contact.phones.filter(function (p) { return p.type; }).map(function (p) { return ({ typeId: p.type.id, phone: p.phone }); }),
      countYearsTogether: Number(_vm.contact.countYearsTogether) || null,
      clientId: (_vm.client && _vm.client.id) || null}),
  },"update":_vm.updateContacts},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var mutate = ref.mutate;
  var loading = ref.loading;
  var error = ref.error;
return [(!loading)?_c('alert',{attrs:{"align":"center","error":error}}):_vm._e(),_c('contact-form',_vm._b({on:{"submit":function($event){return mutate()}}},'contact-form',{
        btnLabel: 'Add',
        loading: loading,
        contact: _vm.contact,
        contacts: _vm.client.contacts || [],
      },false))]}}])},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }