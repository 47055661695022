import ADD_DOCUMENT_MUTATION from '@/graphql/mutations/addDocument.gql';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import DELETE_DOCUMENT_MUTATION from '@/graphql/mutations/deleteDocument.gql';
import PERMISSIONS from '@/enums/permissions';
import UPDATE_DOCUMENT_MUTATION from '@/graphql/mutations/updateDocument.gql';
import accessByPermissions from '@/services/userPermissions';
import Btn from '@/components/Btn/Btn.vue';
import eventHub from '@/utils/eventHub';
import FileInputList from '@/components/FileInputList/FileInputList.vue';
import FileList from '@/components/FileList/FileList.vue';
import Modal from '@/components/Modal/Modal.vue';

export default {
  name: 'Documents',
  components: { Btn, FileInputList, FileList, Modal },
  props: {
    loading: { type: Boolean },
    client: { type: Object },
    showStepButtons: { type: Boolean, default: true },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
  },
  data: () => ({
    fileList: [],
    internalLoading: false,
  }),
  watch: {
    client: {
      handler() {
        this.updateFileList();
      },
      immediate: true,
    },
  },
  computed: {
    documents() {
      return this.client && Array.isArray(this.client.documents)
        ? this.client.documents.map(item => {
            return {
              link: item.documentUrl,
              name: item.name || item.documentUrl,
            };
          }).filter(item => item)
        : [];
    },
    showEditDocuments() {
      return accessByPermissions(PERMISSIONS.MANAGE_DOCUMENTS, this.currentUser);
    },
  },
  methods: {
    change({ value, index }) {
      if (this.client.id && value instanceof File && typeof index === 'number') {
        const variables = {
          clientId: this.client.id,
          documentUrl: '',
          documentFile: value,
          name: value.name,
        };
        let mutation = ADD_DOCUMENT_MUTATION;
        if (this.client.documents[index] && this.client.documents[index].id) {
          variables.id = this.client.documents[index].id;
          mutation = UPDATE_DOCUMENT_MUTATION;
        }
        this.uploadFile(variables, mutation, index);
      }
    },
    remove(index) {
      if (
        this.client.id &&
        typeof index === 'number' &&
        this.client.documents[index] &&
        this.client.documents[index].id
      ) {
        this.removeFile(this.client.documents[index].id);
      }
    },
    updateFileList() {
      this.fileList = [];
      this.client &&
        Array.isArray(this.client.documents) &&
        this.client.documents.forEach(item => {
          if (item) {
            if (item instanceof File) {
              this.fileList.push(item);
            } else if (item.name || item.documentUrl) {
              this.fileList.push(item.name || item.documentUrl);
            }
          }
        });
    },
    removeFile(id) {
      if (id) {
        this.internalLoading = true;
        this.$apollo
          .mutate({
            mutation: DELETE_DOCUMENT_MUTATION,
            variables: {
              id,
            },
          })
          .then(() => {
            eventHub.$emit('show-snackbar', {
              color: 'success',
              text: `File was removed successfully!`,
            });
            this.client.documents = this.client.documents.filter(item => item && item.id !== id);
          })
          .catch(e => {
            this.error = e;
          })
          .finally(() => {
            this.internalLoading = false;
          });
      }
    },
    uploadFile(variables, mutation, index) {
      if (variables && mutation) {
        this.internalLoading = true;
        this.$apollo
          .mutate({
            mutation: mutation,
            variables: {
              input: variables,
            },
          })
          .then(({ data: { addDocument, updateDocument } }) => {
            eventHub.$emit('show-snackbar', {
              color: 'success',
              text: `File was uploaded successfully!`,
            });
            this.$set(this.client.documents, index, addDocument || updateDocument);
            this.$emit('submit', addDocument || updateDocument);
          })
          .catch(e => {
            eventHub.$emit('show-snackbar', {
              color: 'error',
              text: `Error upload file. Max size of the file is 20 MB bytes. Please provide another file.`,
            });
            this.fileList.splice(index, 1);
            this.error = e;
          })
          .finally(() => {
            this.internalLoading = false;
          });
      }
    },
  },
};
