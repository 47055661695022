var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.financialInfo)?_c('div',[_c('v-row',{staticClass:"financial-row-info",attrs:{"no-gutters":""}},[_vm._l((_vm.displayedGeneralInfoData),function(label,index){return _c('v-col',{key:index},[_c('span',{staticClass:"info-list-item-label"},[_vm._v(_vm._s(label.label)+":")]),(label.type && label.type === 'date')?_c('span',{staticClass:"info-list-item-value"},[_vm._v(_vm._s(_vm._f("date")((label.value || '--'))))]):_vm._e(),(label.type && label.type === 'utcDate')?_c('span',{staticClass:"info-list-item-value"},[_vm._v(_vm._s(_vm._f("utcDate")((label.value || '--'))))]):(label.type && label.type === 'longstring' && label.value)?_c('span',{staticClass:"info-list-item-value"},[_c('truncated-text',{attrs:{"text":label.value || '--'}})],1):(label.type && label.type === 'percentage')?_c('span',{staticClass:"info-list-item-value"},[_vm._v(_vm._s(_vm._f("percentage")((label.value || '--'))))]):(label.type && label.type === 'currency')?_c('span',{staticClass:"info-list-item-value"},[_vm._v(_vm._s(_vm._f("currency")((label.value || '--'))))]):(label.type && label.type === 'number')?_c('span',{staticClass:"info-list-item-value"},[_vm._v(_vm._s(_vm._f("number")((label.value || '--'))))]):(label.type && label.type === 'link')?_c('span',{staticClass:"info-list-item-value"},[(label.value)?_c('a',{attrs:{"href":label.value}},[_vm._v("Link")]):[_vm._v(_vm._s('--'))]],2):_c('span',{staticClass:"info-list-item-value"},[_vm._v(_vm._s(label.value || '--'))])])}),_c('modal',{attrs:{"title":"Edit Financial","max-width":480},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showEditFinancial),expression:"showEditFinancial"}],attrs:{"color-type":"accent","width":"109"}},'btn',attrs,false),on),[_vm._v("Edit")])]}},{key:"default",fn:function(ref){
var close = ref.close;
return [_c('financial-info-form',{attrs:{"planId":_vm.planId,"financialInfo":_vm.financialInfo,"clientId":_vm.clientId},on:{"done":close}})]}}],null,false,609426260)})],2),_c('financial-info-tables',{attrs:{"fundingSources":_vm.fundingSources,"planId":_vm.planId,"clientId":_vm.clientId,"financialInfoId":_vm.financialInfoId,"planStartYear":_vm.planStartYear}})],1):_c('v-row',{staticClass:"financial-row-info",attrs:{"no-gutters":""}},[_c('v-col',[_vm._v("No information")]),_c('modal',{attrs:{"title":"Edit Financial","max-width":792},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showEditFinancial),expression:"showEditFinancial"}],attrs:{"color-type":"accent","width":"109"}},'btn',attrs,false),on),[_vm._v("Edit")])]}},{key:"default",fn:function(ref){
var close = ref.close;
return [_c('financial-info-form',{attrs:{"fundingSources":_vm.fundingSources,"planId":_vm.planId,"clientId":_vm.clientId},on:{"done":close}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }