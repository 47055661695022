import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import COMPONENTS_AND_OPTIONS from '@/graphql/queries/componentsAndOptions.gql';
import GENERATE_CASH_REPORT from '@/graphql/queries/generateCashReport.gql';
import GENERATE_DASHBOARD_DETAILS from '@/graphql/queries/generateDashboardData.gql';
import GENERATE_REPORT_DETAILS from '@/graphql/queries/generateDashboardReportDetails.gql';
import GENERATE_REPORTS from '@/graphql/mutations/generateReports.gql';
import PREPARE_CONFIG_FOR_REPORTS from '@/graphql/queries/prepareConfigsForReports.gql';
import Btn from '@/components/Btn/Btn.vue';
import Modal from '@/components/Modal/Modal.vue';
import Card from './Card/Card.vue';
import FullWithCard from './FullWithCard/FullWithCard.vue';
import ReportDetails from './ReportDetails/ReportDetails.vue';
import ReportNameModal from './ReportNameModal/ReportNameModal.vue';
import ExpansionPanelHeader from '@/components/ExpansionPanelHeader/ExpansionPanelHeader.vue';
import NumberTextField from '@/components/NumberTextField/NumberTextField.vue';
import { COMPONENT_STATUS } from '@/enums/componentStatus';
import { generateRangeArray } from '@/utils/generator';
import eventHub from '@/utils/eventHub';
import Highcharts from 'highcharts';

export default {
  name: 'Dashboard',
  components: {
    Btn,
    Card,
    FullWithCard,
    Modal,
    ReportNameModal,
    ExpansionPanelHeader,
    NumberTextField,
    ReportDetails,
  },
  props: {
    client: { type: Object },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
    generateCashReport: {
      query: GENERATE_CASH_REPORT,
      fetchPolicy: 'network-only',
      variables() {
        return { planId: this.planId };
      },
      skip() {
        return !this.planId;
      },
    },
    componentsAndOptions: {
      query: COMPONENTS_AND_OPTIONS,
      variables() {
        return {
          planId: this.planId,
          status: COMPONENT_STATUS.OPEN,
        };
      },
      skip() {
        return !this.planId;
      },
      error(error) {
        this.error = error.networkError ? { ...error, message: 'Something went wrong! Could not load expenditure options.' } : error;
      },
      fetchPolicy: 'cache-first',
    },
    generateDashboardReportDetails: {
      query: GENERATE_REPORT_DETAILS,
      variables() {
        return {
          input: {
            planId: this.planId,
            startYear: this.currentYear.toString(),
            endYear: this.currentYear.toString(),
            fundingSourceOptions: null,
            fundingSourceInvestmentIds: this.fundingSources.map(item => item.id),
            reports: {
              expenditureSpreadsheet: false,
              summaryByFundingSource: true,
              summaryByFacility: false,
              detailedSummaryOfFacilityCosts: false,
              summaryByFiscalYear: false,
              itemizedListOfFacilityCosts: false,
            },
          },
        };
      },
      skip() {
        return !this.planId || !this.fundingSources;
      },
      error(error) {
        this.error = error.networkError ? { ...error, message: 'Something went wrong! Could not load expenditure options.' } : error;
      },
      fetchPolicy: 'network-only',
    },
  },
  data: () => ({
    GENERATE_REPORTS,
    fileList: [],
    loading: false,
    error: null,
    currentYear: new Date().getFullYear(),
    expanded: [0],
    reportTypeModel: 'expenditureSpreadsheet',
    appliedReportTypeModel: '',
    reportTypeListItems: [
      { text: 'Expenditure Spreadsheet', value: 'expenditureSpreadsheet' },
      { text: 'Detailed Summary of Facility Costs', value: 'detailedSummaryOfFacilityCosts' },
      { text: 'Itemized List of Facility Costs', value: 'itemizedListOfFacilityCosts' },
      { text: 'Summary by Facility', value: 'summaryByFacility' },
      { text: 'Summary by Fiscal Year', value: 'summaryByFiscalYear' },
      { text: 'Summary by Funding Source', value: 'summaryByFundingSource' },
    ],
    reportPlanOptionsModel: null,
    reportFundingSourceModel: null,
    reportYearRange: [0, 0],
    isInfographicsLoaded: false,
    infographicsModel: ['needsByYearOptions', 'needsBySystemOptions', 'needsByFacilityOptions', 'annualNeedsVSAllocatedFundingOptions'],
    visibleInfographicsList: [
      'needsByYearOptions',
      'needsBySystemOptions',
      'needsByFacilityOptions',
      'annualNeedsVSAllocatedFundingOptions',
    ],
    infographicsListItems: [
      { text: 'Needs By Year', value: 'needsByYearOptions' },
      { text: 'Needs By System', value: 'needsBySystemOptions' },
      { text: 'Needs By Facility', value: 'needsByFacilityOptions' },
      { text: 'Annual Needs vs Allocated Funding', value: 'annualNeedsVSAllocatedFundingOptions' },
      { text: 'Annual Needs vs Escalating Funding', value: 'annualNeedsEscalatingFundingOptions' },
      {
        text: 'Accumulating Annual Needs vs Allocated Funding with a One Time Infusion',
        value: 'annualNeedsAllocatedFundingWithInfusionOptions',
      },
      {
        text: 'Accumulating Annual Needs vs Escalating Funding with a One Time Infusion',
        value: 'annualNeedsEscalatingFundingWithInfusionOptions',
      },
    ],
    infographicsYearRange: [0, 0],
    isEnergyUsageLoaded: false,
    energyUsageYearModel: new Date().getFullYear().toString(),
    energyUsageGraphs: [
      { text: 'ENERGY USAGE INTENSITY', value: 'energyUsageIntensity' },
      { text: 'ENERGY USAGE COSTS', value: 'energyUsageCosts' },
    ],
    reportDetailsItems: null,
    isReportDetailsLoaded: false,
    reportDataLoading: false,
    infographicsLoading: false,
    energyUsageLoading: false,
    infographicsConfigs: null,
    energyUsageGraphsConfigs: null,
  }),
  watch: {
    maxYear: {
      handler() {
        {
          this.reportYearRange = [this.minYear, +this.minYear + 9];
          this.infographicsYearRange = [this.minYear, +this.minYear + 9];
        }
      },
      immediate: true,
    },
    planId: {
      handler() {
        this.getReportsData();
      },
    },
  },
  mounted() {
    eventHub.$on('to-to-dashboard', () => {
      this.$apollo.queries.generateCashReport.refresh();
      this.$apollo.queries.componentsAndOptions.refresh();
      this.$apollo.queries.generateDashboardReportDetails.refresh();
      this.isReportDetailsLoaded = false;
      this.isEnergyUsageLoaded = false;
      this.isInfographicsLoaded = false;
      this.expanded = [0];
      this.getReportsData();
    });
  },
  computed: {
    generateCashReportLoading() {
      return this.$apollo.queries.generateCashReport.loading;
    },
    generateCashFlowReportLoading() {
      return this.$apollo.queries.generateDashboardReportDetails.loading;
    },
    expenditureOptionsList() {
      return this.componentsAndOptions?.expenditureOptions || [];
    },
    facilityList() {
      return this.client?.facilities || [];
    },
    plan() {
      return this.client?.plans[0] || null;
    },
    planId() {
      return this.plan?.id || null;
    },
    minYear() {
      return +this.plan?.financialInfo?.planStartYear || this.currentYear;
    },
    maxYear() {
      return +this.minYear + 20;
    },
    clientCashflow() {
      if (
        this.generateDashboardReportDetails &&
        this.generateDashboardReportDetails['summaryByFundingSource'] &&
        typeof this.generateDashboardReportDetails['summaryByFundingSource'] === 'string'
      ) {
        try {
          let totalStartingBallanceRow = 0;
          let totalRevenueRow = 0;
          let totalExpensesRow = 0;
          let totalProjectedBallanceRow = 0;

          let reportDetailsData = JSON.parse(this.generateDashboardReportDetails['summaryByFundingSource']);
          let fundingData = reportDetailsData.groupData;
          for (let fundingIndex = 0; fundingIndex < fundingData.length; fundingIndex += 5) {
            const customStartingBallance = fundingData[fundingIndex + 1][1];
            const customRevenue = fundingData[fundingIndex + 2][1];
            const expenses = fundingData[fundingIndex + 3][1];
            const projectedBallance = fundingData[fundingIndex + 4][1];
            totalStartingBallanceRow += customStartingBallance;
            totalRevenueRow += customRevenue;
            totalExpensesRow += expenses;
            totalProjectedBallanceRow += projectedBallance;
          }
          return [
            {
              name: 'Total Starting Balance',
              value: totalStartingBallanceRow,
            },
            {
              name: 'Total Anticipated Revenue',
              value: totalRevenueRow,
            },
            {
              name: 'Total Anticipated Expenses',
              value: totalExpensesRow,
            },
            {
              name: 'Total Projected EOY Balance',
              value: totalProjectedBallanceRow,
            },
          ];
        } catch (e) {
          console.warn('Cant parse Report Details data for ' + 'summaryByFundingSource', e);
        }
        return [];
      }
      return [];
    },
    energyUsageYearRange() {
      return generateRangeArray(this.minYear - 20, this.minYear + 20).reverse();
    },
    fundingSources() {
      return this?.plan?.financialInfo?.fundingSources || [];
    },
    yearSubtitle() {
      return 'For the current ' + this.currentYear + ' year';
    },
    topExpensesResponse() {
      return this.generateCashReport?.topExpensesResponse || [];
    },
    euiResponseItems() {
      let euiData = this.generateCashReport?.euiResponse || [];
      if (euiData[0] && euiData[1]) {
        return euiData.map((item, index) => {
          if (item) {
            return Object.assign(item, { color: index > 0 ? '#F47A45' : '#236BE1' });
          }
        });
      }
      return [];
    },
    budgetTableCustomCells() {
      const result = {
        revenue: {},
        startingBallance: {},
      };
      this.fundingSources.forEach(source => {
        try {
          if (typeof source.revenue === 'string') {
            result.revenue[source.id] = JSON.parse(source.revenue);
          }
          if (typeof source.balance === 'string') {
            result.startingBallance[source.id] = JSON.parse(source.balance);
          }
        } catch (e) {
          console.warn('fundingSources budget table parse error', {
            id: source.id,
            error: e,
          });
        }
      });
      return result;
    },
    exportVariablesReportDetails() {
      let input = {
        planId: this.planId,
        expenditureOption: this.getReportComponentData(),
        fundingSourceOptions: this.getFundingSourceOptions(),
        fundingSourceInvestmentIds: this.reportFundingSourceModel,
        reports: {},
        startYear: this.reportYearRange[0].toString(),
        endYear: this.reportYearRange[1].toString(),
      };
      input.reports[this.reportTypeModel] = true;
      return input;
    },
    exportVariablesInfographics() {
      let variables = {
        planId: this.planId,
        reports: {
          gapsAnalysis: true,
        },
        selectedGraphs: {},
        startYear: this.infographicsYearRange[0].toString(),
        endYear: this.infographicsYearRange[1].toString(),
      };
      this.visibleInfographicsList.forEach(infographic => {
        variables.selectedGraphs[infographic] = true;
      });
      return variables;
    },
    exportVariablesEnergyUsage() {
      return {
        planId: this.planId,
        reports: {
          energyUsageCharts: true,
        },
        startYear: this.energyUsageYearModel.toString(),
      };
    },
  },
  methods: {
    drawFullWithChart(item, configName) {
      this.$nextTick(() => {
        if (this[configName] && item) {
          const data = this[configName][item.value];
          const chartName = 'fullWith' + item.value;
          if (typeof data === 'string') {
            this[chartName] && this[chartName].destroy();
            const container = document.querySelector(`.f-card__modal-content #${item.value}`);
            container.classList.add('-full-with');
            const chartData = JSON.parse(data);
            chartData.title = '';
            chartData.subtitle = '';
            this[chartName] = Highcharts.chart(container, chartData);
          }
        }
      });
    },
    drawChartByName(chartName, data) {
      this[chartName] && this[chartName].destroy();
      if (data) {
        const chartData = JSON.parse(data);
        chartData.title = '';
        chartData.subtitle = '';
        this[chartName] = Highcharts.chart(chartName, chartData);
      }
    },
    getABSNumberValue(number) {
      return Math.abs(number);
    },
    onChangeTypeInfographics(e) {
      if (e.length > 4) {
        e.pop();
      }
    },
    getInfographicsData() {
      if (this.planId) {
        this.infographicsLoading = true;
        let inputData = {
          planId: this.planId,
          startYear: this.infographicsYearRange[0] ? this.infographicsYearRange[0].toString() : this.minYear.toString(),
          endYear: this.infographicsYearRange[1] ? this.infographicsYearRange[1].toString() : this.minYear.toString(),
          reports: {
            allocatedFundingAmount: false,
            escalatingFundingAmount: false,
            annualNeedsVSAllocatedFundingOptions: false,
            annualNeedsEscalatingFundingOptions: false,
            annualNeedsEscalatingFundingWithInfusionOptions: false,
            annualNeedsAllocatedFundingWithInfusionOptions: false,
            needsByYearOptions: false,
            needsByFacilityOptions: false,
            needsBySystemOptions: false,
          },
        };
        for (let key in inputData.reports) {
          if (this.infographicsModel.indexOf(key) > -1) {
            inputData.reports[key] = true;
          }
        }
        this.isInfographicsLoaded = true;
        this.visibleInfographicsList = this.infographicsModel;
        this.$apollo
          .query({
            query: PREPARE_CONFIG_FOR_REPORTS,
            variables: { input: inputData },
            fetchPolicy: 'network-only',
          })
          .then(({ data }) => {
            this.infographicsLoading = false;
            if (data.prepareConfigsForReports) {
              this.infographicsConfigs = data.prepareConfigsForReports;
              for (let chartName in data.prepareConfigsForReports) {
                const chartData = data.prepareConfigsForReports[chartName];
                typeof chartData === 'string' && this.drawChartByName(chartName, chartData);
              }
            }
          });
      }
    },
    getFundingSourceOptions() {
      if (!this.reportPlanOptionsModel) {
        return null;
      }
      const result = {};
      let table;
      try {
        const options = JSON.parse(this.expenditureOptionsList.find(item => item.id === this.reportPlanOptionsModel).options);
        table = options.table;
      } catch (e) {
        console.warn('Cant parse Report Details options for option id' + this.reportPlanOptionsModel, e);
        return null;
      }
      if (!table?.revenue && !table?.startingBallance) {
        return null;
      }
      if (table.revenue) {
        Object.keys(table.revenue).forEach(sourceId => {
          result[sourceId] = {
            revenue: table.revenue[sourceId],
          };
        });
      }
      if (table.startingBallance) {
        Object.keys(table.startingBallance).forEach(sourceId => {
          if (!result[sourceId]) {
            result[sourceId] = {
              // note! startingBalance in backend, but startingBallance in front, the number of letters L is different
              startingBalance: table.startingBallance[sourceId],
            };
          } else {
            result[sourceId].startingBalance = table.startingBallance[sourceId];
          }
        });
      }

      return JSON.stringify(result);
    },
    getReportComponentData() {
      if (!this.reportPlanOptionsModel) {
        return null;
      }
      return {
        id: this.reportPlanOptionsModel,
      };
    },
    getReportsData() {
      if (this.planId) {
        this.reportDataLoading = true;
        let inputData = {
          planId: this.planId,
          startYear: this.reportYearRange[0].toString(),
          endYear: this.reportYearRange[1].toString(),
          fundingSourceOptions: this.getFundingSourceOptions(),
          expenditureOption: this.getReportComponentData(),
          fundingSourceInvestmentIds: this.reportFundingSourceModel,
          reports: {
            expenditureSpreadsheet: false,
            summaryByFundingSource: false,
            summaryByFacility: false,
            detailedSummaryOfFacilityCosts: false,
            summaryByFiscalYear: false,
            itemizedListOfFacilityCosts: false,
          },
        };
        inputData.reports[this.reportTypeModel] = true;
        this.$apollo
          .query({
            query: GENERATE_REPORT_DETAILS,
            variables: { input: inputData },
            fetchPolicy: 'network-only',
          })
          .then(({ data }) => {
            const reportDetailsData = data.generateDashboardReportDetails[this.reportTypeModel];
            if (typeof reportDetailsData === 'string') {
              try {
                this.appliedReportTypeModel = this.reportTypeModel;
                this.reportDetailsItems = JSON.parse(reportDetailsData);
              } catch (e) {
                console.warn('Cant parse Report Details data for ' + this.reportTypeModel, e);
              }
            }
            this.isReportDetailsLoaded = true;
            this.reportDataLoading = false;
          })
          .catch(e => {
            this.isReportDetailsLoaded = true;
            this.reportDataLoading = false;
            console.warn('Cant parse Report Details data for ' + this.reportTypeModel, e);
          });
      }
    },
    getEnergyUsageGraphs() {
      if (this.client?.id) {
        this.energyUsageLoading = true;
        let inputData = {
          clientId: this.client.id,
          energyUsageGraphs: { year: this.energyUsageYearModel.toString() },
        };
        this.$apollo
          .query({
            query: GENERATE_DASHBOARD_DETAILS,
            variables: { input: inputData },
            fetchPolicy: 'network-only',
          })
          .then(({ data }) => {
            if (data.generateDashboardData && data.generateDashboardData.energyUsageGraphs) {
              this.isEnergyUsageLoaded = true;
              this.energyUsageLoading = false;
              this.energyUsageGraphsConfigs = data.generateDashboardData.energyUsageGraphs;
              for (let chartName in this.energyUsageGraphsConfigs) {
                let chartData = this.energyUsageGraphsConfigs[chartName];
                const parsedData = JSON.parse(chartData);
                // fix wrong data alignment for zero values
                if (!parsedData.series[0].data.reduce((partialSum, a) => partialSum + a, 0)) {
                  parsedData.yAxis.max = 100;
                  chartData = JSON.stringify(parsedData);
                }
                this.energyUsageGraphsConfigs[chartName] = chartData;
                typeof chartData === 'string' && this.drawChartByName(chartName, chartData);
              }
            }
          });
      }
    },
    loadDataOnExpand(flag, query) {
      this.$nextTick(() => {
        if (flag === false) {
          query();
        }
      });
    },
  },
};
