/* eslint-disable */
export const sortByName = ((a, b) => {
    if(a && b && typeof a.name === 'string' && typeof b.name === 'string') {
        const left = a.name.toLowerCase();
        const right = b.name.toLowerCase();
        if(left < right) { return -1; }
        if(left > right) { return 1; }
        return 0;
    }
    return 1
  });
  