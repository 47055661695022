import { getHeadersSelection, setHeadersSelection } from '@/services/userState';

export default {
  name: 'DataTableColumns',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Array,
    },
    headers: {
      type: Array,
    },
    uId: {
      type: String,
    },
  },
  data: () => ({
    menuOpen: false,
    selected: [],
  }),
  watch: {
    headers: {
      immediate: true,
      handler(defaultHeaders) {
        if (this.value === null && defaultHeaders && defaultHeaders.length > 0) {
          //selected all by default
          let selectedResult = defaultHeaders.map((_, index) => index);
          if (this.uId) {
            const storedHeaders = getHeadersSelection(this.uId);
            //validate selected headers
            if (storedHeaders && storedHeaders.length === defaultHeaders.length) {
              let selected = [];
              const validHeaders = storedHeaders.every(storedHeader => {
                const index = defaultHeaders.findIndex(defaultHeader => storedHeader.value === defaultHeader.value);
                if (index > -1 && storedHeader.selected) {
                  selected.push(index);
                }
                return index > -1;
              });

              if (!validHeaders) {
                //stored headers is not up to date, need to clean up
                setHeadersSelection(this.uId, undefined);
              } else {
                selectedResult = selected;
              }
            } else {
              setHeadersSelection(this.uId, undefined);
            }
          }
          this.selected = selectedResult;
          this.$emit('change', selectedResult);
        }
      },
    },
    value: {
      immediate: true,
      handler(val) {
        if (val !== null && JSON.stringify(this.selected) !== JSON.stringify(val)) {
          this.selected = val;
        }
      },
    },
    menuOpen(value) {
      if (!value) {
        if (JSON.stringify(this.selected) !== JSON.stringify(this.value)) {
          if (this.uId) {
            const headers = this.headers.map((item, index) => {
              return { ...item, selected: this.selected.includes(index) };
            });
            setHeadersSelection(this.uId, headers);
          }
          this.$emit('change', this.selected);
        }
      }
    },
  },
  methods: {
    onModalClose() {
      this.menuOpen = false;
    },
  },
};
