import Vue from 'vue';
import { VDataTable } from 'vuetify/lib';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import Btn from '@/components/Btn/Btn.vue';
import TruncatedText from '@/components/TruncatedText/TruncatedText.vue';
import { getObjValue } from '@/utils/objExt';
import { getFilters, setFilters } from '@/services/userState';
import { cloneDeep } from 'lodash';

// Create Base Mixins and Define Custom Properties
const base = Vue.extend({ mixins: [VDataTable] });
export default base.extend({
  name: 'DataTable',
  components: {
    VDataTable,
    DatePicker,
    Btn,
    TruncatedText,
  },
  props: {
    itemsPerPage: { type: Number, default: 10 },
    emptyGroupText: { type: String, default: 'No items' },
    selectLabel: { type: String },
    resetBtnParams: {
      type: [Object, Boolean],
      default: true,
    },
    enableStoredFilter: { type: Boolean, default: true },
  },
  data() {
    return {
      filteredItems: [...this.items] || [],
      sorting: null,
      hasHScroll: false,
      observer: null,
      resizeObserver: null,
      headerHeight: 0,
      headerStyles: {},
      expandedGroups: [],
      internalGroupDesc: false,
      filters: {},
      tableVisible: false, //need to detect when parent hide/show content via display: none
      isDefaultFiltersSetted: false,
      lastSelectedFilter: null,
      disableSmartFilter: true,
    };
  },
  mounted() {
    this.initResizeObserver();
    this.initScrollHandler();
    this.$emit('mounted');
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  watch: {
    items: {
      immediate: true,
      handler(newValue, oldValue) {
        if (Array.isArray(newValue)) {
          if (!oldValue || oldValue.length !== newValue.length) {
            this.updateFilters();
            this.filter();
          } else {
            if (this.itemKey) {
              this.filteredItems.forEach((currentItem, index) => {
                const item = newValue.find(item => item[this.itemKey] === currentItem[this.itemKey]);
                if (item) {
                  this.filteredItems[index] = item;
                } else {
                  //need to reset sorting and filtering when item key is changed
                  this.reset(newValue);
                  return false;
                }
              });
            }
            this.updateFilters();
          }
          this.recalcHeaderStyles();
        }
      },
      deep: true,
    },
    tableVisible(value) {
      if (value) {
        this.initObserver();
      } else {
        if (this.observer) {
          this.observer.disconnect();
        }
      }
    },
    filteredItems(value) {
      if (!this.isFilterEmpty) {
        this.$emit('filtered', value, cloneDeep(Object.values(this.filters)));
      } else {
        this.$emit('filtered', []);
      }
      this.updateFilters();
      this.enableStoredFilter && setFilters(this.$route.name, this.filters);
    },
    headers: {
      immediate: true,
      handler(value, oldVal) {
        if (value && oldVal && value.length !== oldVal.length) {
          //need to reset sorting and filtering when number of displayed columns is changed
          this.reset(this.items);
        } else {
          this.updateFilters();
        }
      },
      deep: true,
    },
  },
  computed: {
    resetBtn() {
      let result = {
        show: false,
        isAbsolute: false,
      };
      if (this.resetBtnParams) {
        if (typeof this.resetBtnParams === 'object') {
          result = Object.assign({}, result, this.resetBtnParams);
        } else {
          result.show = true;
        }
      }
      return result;
    },
    isFilter() {
      return !!this.headers.some(h => h.type);
    },
    selectedFilters() {
      const result = {};
      Object.keys(this.filters).forEach(keyName => {
        const f = this.filters[keyName];
        if (f.value && !(Array.isArray(f.value) && !f.value.length)) {
          result[keyName] = f;
        }
      });
      return result;
    },
    isFilterEmpty() {
      return !Object.keys(this.selectedFilters).length;
    },
    subHeaderStyle() {
      return {
        top: this.headerHeight + 'px',
      };
    },
  },
  methods: {
    getHeaderElem() {
      let header = this.$refs?.header;
      if (!header) {
        header = typeof this.$scopedSlots?.header === 'function' && this.$scopedSlots.header()[0]?.context?.$refs?.header;
      }
      if (!header) {
        header = this.$refs?.table?.querySelector('thead>tr');
      }
      return header || document.createElement('table'); // dump table to avoid errors
    },
    getTHeadElem() {
      let thead = this.$refs?.thead;
      if (!thead) {
        thead = typeof this.$scopedSlots?.header === 'function' && this.$scopedSlots.header()[0]?.context?.$refs?.thead;
      }
      if (!thead) {
        thead = this.$refs?.table?.querySelector('thead');
      }
      return thead || document.createElement('table'); // dump table to avoid errors
    },
    filter() {
      const actives = this.selectedFilters;
      this.filteredItems = this.items.filter(item => {
        for (let keyName in actives) {
          if (keyName in item) {
            const value = String(item[keyName]).toLowerCase();
            const filterValue = actives[keyName].value;
            if (Array.isArray(filterValue)) {
              if (!filterValue.filter(f => String(f).toLowerCase() === value).length) {
                return false;
              }
            } else if (!value.includes(String(filterValue).toLowerCase())) {
              return false;
            }
          }
        }
        return true;
      });
    },
    sort() {
      if (!this.sorting) return;
      const { name, order, type } = this.sorting;
      let sortFunc = null;
      switch (type) {
        case 'number':
        case 'year':
        case 'currency':
          sortFunc = function(a, b) {
            return a[name] - b[name];
          };
          break;
        case 'date':
          sortFunc = function(a, b) {
            return new Date(a[name]).getTime() - new Date(b[name]).getTime();
          };
          break;
        default:
          sortFunc = function(a, b) {
            const _a = a ? getObjValue(a, name) : '';
            const _b = b ? getObjValue(b, name) : '';
            if (_a > _b) {
              return 1;
            } else if (_a < _b) {
              return -1;
            }
            return 0;
          };
      }
      const sorted = this.filteredItems.sort(sortFunc);
      this.filteredItems = order === 'desc' ? sorted.reverse() : sorted;
    },
    isGroupExpanded(name) {
      return this.expandedGroups.includes(name);
    },
    isGroupContainChildren(items, headers) {
      return (items.length === 1 && headers.some(header => items[0][header.value])) || items.length > 1;
    },
    isAscActive(header) {
      return (
        (this.groupBy === header.value && !this.internalGroupDesc) ||
        (this.sorting && this.sorting.name === header.value && this.sorting.order === 'asc')
      );
    },
    isDescActive(header) {
      return (
        (this.groupBy === header.value && this.internalGroupDesc) ||
        (this.sorting && this.sorting.name === header.value && this.sorting.order === 'desc')
      );
    },
    onFilter(value) {
      this.lastSelectedFilter = value;
      this.filter();
      this.sort();
      this.recalcHeaderStyles();
    },
    onSorting(header) {
      if (this.groupBy && this.groupBy === header.value) {
        this.internalGroupDesc = !this.internalGroupDesc;
      } else {
        let order = 'asc';
        if (this.sorting && header.value === this.sorting.name) {
          order = this.sorting.order === 'asc' ? 'desc' : 'asc';
        }
        this.sorting = { name: header.value, order: order, type: header.dataType || header.type };
      }
      this.sort();
    },
    onGroupToggle(name) {
      const index = this.expandedGroups.indexOf(name);
      if (index > -1) {
        this.expandedGroups.splice(index, 1);
      } else {
        this.expandedGroups.push(name);
      }
    },
    initObserver() {
      const containerEl = this.getHeaderElem();
      if (this.observer) {
        this.observer.disconnect();
      }
      const observer = new IntersectionObserver(
        ([e]) => {
          this.hasHScroll = e.boundingClientRect.width !== e.intersectionRect.width && e.intersectionRatio < 1;
        },
        { threshold: [1], root: this.getTHeadElem()?.parentElement?.parentElement }
      );
      observer.observe(containerEl);
      this.observer = observer;
    },
    initResizeObserver() {
      const containerEl = this.getHeaderElem();
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
      const observer = new ResizeObserver(entries => {
        if (entries[0]) {
          if (
            entries[0].contentRect.width === 0 &&
            this.getTHeadElem()?.parentElement?.parentElement?.offsetWidth === 0 &&
            this.tableVisible
          ) {
            this.tableVisible = false;
          } else if (entries[0].contentRect.width > 0 && !this.tableVisible) {
            this.tableVisible = true;
          }
          this.headerHeight = entries[0].contentRect.height;
          this.recalcHeaderStyles();
        }
      });
      observer.observe(containerEl);
      this.resizeObserver = observer;
    },
    initScrollHandler() {
      if (this.$refs.table && this.$refs.table.$el) {
        const container = this.$refs.table.$el.querySelector('.v-data-table__wrapper');
        if (container) {
          let scrollStart = true;
          const func = () => {
            if (scrollStart) {
              container.click();
              scrollStart = false;
            }
          };
          let timer;
          const timout = () => {
            func();
            clearTimeout(timer);
            timer = setTimeout(() => {
              scrollStart = true;
            }, 100);
          };
          container.addEventListener('scroll', timout);
        }
      }
    },
    setDefaultFilterValues() {
      if (this.enableStoredFilter && !this.isDefaultFiltersSetted) {
        this.isDefaultFiltersSetted = true;
        const result = getFilters(this.$route.name);
        if (result && this.filters) {
          Object.keys(this.filters).forEach(filterName => {
            result[filterName] && (this.filters[filterName].value = result[filterName].value);
          });
          this.filter();
          const filteredItems = Object.values(this.filters).some(f => f.value !== '' && f.value !== null && f.value.length !== 0)
            ? this.filteredItems
            : [];
          this.$emit('filtered', filteredItems, Object.values(cloneDeep(this.filters)));
        }
      }
    },
    updateFilters(reset = false) {
      if (this.isFilter) {
        const result = {};
        this.headers.forEach(header => {
          let options = this.generateOptionsForFilter(header);
          const filter = this.filters[header.value];
          let value = null;
          if (!reset && filter && 'value' in filter) {
            value = filter.value;
          }
          result[header.value] = {
            options,
            value,
            type: header.type,
            name: header.value,
            width: header.width,
            fixed: header.fixed,
          };
        });
        this.filters = result;
        this.setDefaultFilterValues();
      } else {
        this.filters = {};
        this.lastSelectedFilter = null;
      }
    },
    generateOptionsForFilter(header) {
      let options;
      const items = this.disableSmartFilter ? this.items : this.filteredItems;
      const lastSelectedFilter = this.disableSmartFilter && this.lastSelectedFilter;
      if (header.type === 'select') {
        if (header.options) {
          options = cloneDeep(header.options);
        } else if (items) {
          if (
            lastSelectedFilter &&
            lastSelectedFilter.value &&
            lastSelectedFilter.value.length &&
            lastSelectedFilter.name === header.value &&
            lastSelectedFilter.type === 'select'
          ) {
            options = lastSelectedFilter.options;
          } else {
            let optionSet = new Set();
            items.forEach(item => {
              const currentCell = item[header.value];
              currentCell && optionSet.add(currentCell);
            });
            options = Array.from(optionSet);
          }
        }
        Array.isArray(options) && !header.noSort && options.sort(this.sortWithLowerCase);
      }
      return options;
    },
    sortWithLowerCase(a, b) {
      const left = a.toLowerCase();
      const right = b.toLowerCase();
      if (left < right) {
        return -1;
      }
      if (left > right) {
        return 1;
      }
      return 0;
    },
    reset(items) {
      this.updateFilters(true);
      this.sorting = null;
      this.filteredItems = [...items];
      this.lastSelectedFilter = null;
    },
    onResetBtn() {
      this.reset(this.items);
      this.$emit('reset');
    },
    recalcHeaderStyles() {
      const headerElem = this.getHeaderElem();
      if (headerElem && this.$refs.subHeader && this.getTHeadElem()) {
        this.$nextTick(() => {
          const columnWidth = [];
          const headerNodes = headerElem.querySelectorAll(':scope>th.fixed');
          const subHeaderNodes = this.$refs.subHeader.querySelectorAll(':scope>th.fixed');
          const rowNodes = this.getTHeadElem()?.parentElement?.querySelectorAll('tbody>tr');
          headerNodes.forEach((node, index) => {
            let leftOffset = 0;
            for (let i = 0; i < index; i++) {
              leftOffset += headerNodes[i].offsetWidth;
            }
            node.style.left = leftOffset + 'px';
            columnWidth.push(node.style.left);
          });
          subHeaderNodes.forEach((item, index) => {
            columnWidth[index] && (item.style.left = columnWidth[index]);
          });
          rowNodes.forEach(row => {
            row.querySelectorAll(':scope>td.fixed').forEach((item, index) => {
              columnWidth[index] && (item.style.left = columnWidth[index]);
            });
          });
        });
      }
    },
  },
});
