import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { onError } from '@apollo/client/link/error';
import { Observable } from '@apollo/client/utilities/observables/Observable';
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client';
import REFRESH_AUTH_TOKEN from '@/graphql/mutations/refreshToken.gql';
import router from '@/router';
import eventHub from '@/utils/eventHub';

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token';
const AUTH_REFRESH_TOKEN = 'apollo-refresh-token';
let provider;

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.extensions.code) {
        case 'UNAUTHENTICATED': {
          const oldHeaders = operation.getContext().headers;
          const token = typeof localStorage !== 'undefined' ? localStorage.getItem(AUTH_REFRESH_TOKEN) : '';
          if (token) {
            const apolloClient = provider.defaultClient;
            return new Observable(observer => {
              apolloClient
                .mutate({
                  mutation: REFRESH_AUTH_TOKEN,
                  variables: {
                    token,
                  },
                })
                .then(({ data }) => {
                  const {
                    refreshToken: { accessToken, refreshToken },
                  } = data;
                  localStorage.setItem(AUTH_TOKEN, accessToken);
                  localStorage.setItem(AUTH_REFRESH_TOKEN, refreshToken);
                  operation.setContext({
                    headers: {
                      ...oldHeaders,
                      Authorization: `Bearer ${accessToken}`,
                    },
                  });
                })
                .then(() => {
                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                  };
                  // Retry last failed request
                  forward(operation).subscribe(subscriber);
                })
                .catch(async error => {
                  observer.error(error);
                  await onLogout(apolloClient);
                  if (router.currentRoute.name !== 'login') {
                    router.push({ name: 'login' });
                  }
                });
            });
          } else {
            if (router.currentRoute.name !== 'login') {
              router.push({ name: 'login' });
            }
            return false;
          }
        }
        case 'FORBIDDEN': {
          onLogout(provider.defaultClient).then(() => {
            if (router.currentRoute.name !== 'login') {
              router.push({ name: 'login' });
            }
          });
          return false;
        }
      }
    }
  }

  if (networkError) {
    eventHub.$emit('show-snackbar', { color: 'error', text: 'Error! Something went wrong... Please try again later' });
    console.log(`[Network error]: ${networkError}`);
  }
});

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:4000/graphql';
// Files URL root
export const filesRoot = process.env.VUE_APP_FILES_ROOT || httpEndpoint.substr(0, httpEndpoint.indexOf('/graphql'));

Vue.prototype.$filesRoot = filesRoot;

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  refreshTokenName: AUTH_REFRESH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  link: errorLink,

  // Override default cache
  inMemoryCacheOptions: { addTypename: false },

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

// Call this in the Vue app file
function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  });

  // Create vue apollo provider
  return new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      $mutation: {
        errorPolicy: 'all',
      },
    },
  });
}

export function isAuthenticated() {
  return typeof localStorage !== 'undefined' && localStorage.getItem(AUTH_TOKEN);
}

// Manually call this when user log in
export async function onLogin(apolloClient, token, refreshToken) {
  if (typeof localStorage !== 'undefined' && token && refreshToken) {
    localStorage.setItem(AUTH_TOKEN, token);
    localStorage.setItem(AUTH_REFRESH_TOKEN, refreshToken);
  }
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(AUTH_REFRESH_TOKEN);
  }
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
  }
}

provider = createProvider();

export default provider;
