import Btn from '@/components/Btn/Btn.vue';
import ComponentsReport from '@/components/ComponentsReport/ComponentsReport.vue';
import Modal from '@/components/Modal/Modal.vue';
import eventHub from '@/utils/eventHub';

export default {
  name: 'ComponentsReportModal',
  components: { ComponentsReport, Btn, Modal },
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
    disableYearSlider: {
      type: Boolean,
      default: true,
    },
    plan: {
      type: Object,
      default: null,
    },
    fundingSourceOptions: {
      type: Object,
      default: null,
    },
    fundingSourceInvestmentIds: {
      type: Array,
      default: null,
    },
    components: {
      type: Array,
      default: null,
    },
    expenditureOption: {
      type: Array,
      default: null,
    },
    btnTitle: {
      type: String,
      default: 'Report',
    },
    modalTitle: {
      type: String,
      default: 'Generate Report',
    },
  },
  methods: {
    onDone(close) {
      this.$emit('done');
      close();
    },
    goToTab(tabName, close) {
      close();
      eventHub.$emit('go-to-tab', tabName);
      this.$emit('done');
    },
  },
  computed: {
    isFinancialFilled() {
      return !!this.plan?.financialInfo;
    },
  },
};
