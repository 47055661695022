import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
    themes: {
      light: {
        primary: '#2D7372',
        secondary: '#1564b7',
        accent: '#4FC2BD',
        accent1: '#99D9D9',
        accent2: '#F6DDB9',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        background: '#fff',
        menu: '#2D7372',
        'ics-light-grey-blue': '#eff1f1',
        'ics-light-grey': '#C8C8C8',
        'ics-grey': '#989899',
      },
      dark: {
        primary: '#c9d9d9',
        secondary: '#1564b7',
        accent: '#4FC2BD',
        accent1: '#51739f',
        accent2: '#4a6a93',
        error: '#ff8181',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        background: '#435252',
        menu: '#2D7372',
        anchor: '#63c5c3',
        'ics-light-grey-blue': '#5c6868',
        'ics-light-grey': '#3c4046',
        'ics-grey': '#b0b3b7',
      },
    },
    options: { customProperties: true, variations: false },
  },
});
