import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import ENERGY_USAGE_QUERY from '@/graphql/queries/getEnergyUsageByYear.gql';
import ADD_ENERGY_USAGE from '@/graphql/mutations/addEnergyUsage.gql';
import UPDATE_ENERGY_USAGE from '@/graphql/mutations/updateEnergyUsage.gql';
import Btn from '@/components/Btn/Btn.vue';
import eventHub from '@/utils/eventHub';
import ExpansionPanelHeader from '@/components/ExpansionPanelHeader/ExpansionPanelHeader.vue';
import Modal from '@/components/Modal/Modal.vue';
import SimpleEditableTable from '@/components/SimpleEditableTable/SimpleEditableTable.vue';
import { errorFilter } from '@/filters/errorMsg';

let updateTimeout = 0;

const ENERGY_USAGE_TYPE = {
  electric: 'electric',
  gas: 'gas',
  steam: 'steam',
  fuelOil: 'fuelOil',
  propane: 'propane',
};

const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const UNITS = {
  electric: {
    kWh: 3412,
  },
  gas: {
    Therm: 99976.1,
    MMBTU: 1000000,
    kBTU: 1000,
    CCF: 103700,
    MCF: 1020000,
  },
  steam: {
    lb: 1194,
    MMBTU: 1000000,
  },
  fuelOil: {
    'Gal of #1 Kerosene': 135000,
    'Gal of #2 Fuel Oil': 138000,
    'Gal of #4 Fuel Oil': 145000,
    'Gal of #6 Fuel Oil': 150000,
  },
  propane: {
    Gallons: 91600,
    'Cubic feet': 2500,
  },
};

function getDefaultUnitTypeName(constant) {
  return constant ? Object.keys(constant)[0] : '';
}

function getUnitTypeList(constant) {
  return constant ? Object.keys(constant) : [];
}

function generateDefaultEnergyModels() {
  return Object.keys(ENERGY_USAGE_TYPE).reduce((models, unitName) => {
    models[unitName + 'Model'] = {
      table: null,
      type: ENERGY_USAGE_TYPE[unitName],
      unitType: getDefaultUnitTypeName(UNITS[unitName]),
      id: 0,
    };
    return models;
  }, {});
}

export default {
  name: 'EnergyUsageDetails',
  components: { Btn, ExpansionPanelHeader, Modal, SimpleEditableTable },
  props: {
    facilities: {
      type: Array,
      default: () => [],
    },
    facilityId: {
      type: String,
      default: '0',
    },
    planStartYear: {
      type: Number,
      default: 1970,
    },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
    getEnergyUsageByYear: {
      query: ENERGY_USAGE_QUERY,
      variables() {
        return {
          facilityId: this.selectedFacility,
          year: this.selectedYear.toString(),
        };
      },
      skip() {
        return !this.selectedFacility;
      },
      error(error) {
        this.error = error.networkError ? { ...error, message: 'Something went wrong! Could not load energy usage details.' } : error;
      },
      fetchPolicy: 'network-only',
    },
  },
  data: vm => ({
    error: null,
    expanded: [],
    currentUser: null,
    getEnergyUsageByYear: null,
    selectedYear: vm.planStartYear,
    selectedFacility: vm.facilityId,
    ...generateDefaultEnergyModels(),
  }),
  watch: {
    facilityId: {
      handler() {
        this.selectedFacility = this.facilityId;
      },
      immediate: true,
    },
    planStartYear: {
      handler() {
        this.selectedYear = this.planStartYear;
      },
      immediate: true,
    },
    getEnergyUsageByYear() {
      if (this.getEnergyUsageByYear) {
        Object.keys(ENERGY_USAGE_TYPE).forEach(energyName => {
          const model = this[energyName + 'Model'];
          const { table, id } = this.parseTableFromQuery(
            this.getEnergyUsageByYear.find(item => item.energyType === ENERGY_USAGE_TYPE[energyName])
          );
          model.id = id || 0;
          if (table) {
            model.table = table;
            model.unitType = table[0].unitType?.value || getDefaultUnitTypeName(UNITS[energyName]);
          }
        });
      }
    },
  },
  computed: {
    selectedSquare() {
      return this.facilities.find(item => item.id === this.selectedFacility)?.squareFootage || 0;
    },
    yearList() {
      const startYear = this.planStartYear - 20;
      return Array.from(Array(40).keys()).map(item => item + startYear);
    },
    facilityName() {
      return this.facilities.find(item => item.id === this.facilityId)?.name || '';
    },
    loading() {
      return this.$apollo.queries.getEnergyUsageByYear.loading;
    },
    electricItems: {
      get() {
        let result = this.electricModel.table;
        if (!result) {
          result = this.generateEmptyTable(this.electricHeaders, 'Electric Total');
        }
        result.forEach(row => {
          this.electricHeaders.forEach(header => {
            switch (header.value) {
              case 'unitType':
                row.unitType.value = this.electricModel.unitType;
                break;
              case 'btu':
                row.btu.value = this.getBTU(row.usage.value, UNITS.electric[this.electricModel.unitType]);
                break;
              case 'demandCost':
                row.demandCost.value = this.getDemandCost(row.demandUsage.value, row.demandUnitRate.value);
                break;
              case 'baseConsumptionCost':
                row.baseConsumptionCost.value = this.getCost(row.usage.value, row.unitRate.value);
                break;
              case 'totalCost':
                row.totalCost.value = this.getTotalElectricCost(
                  row.demandCost.value,
                  row.baseConsumptionCost.value,
                  row.otherCharges.value
                );
                break;
            }
          });
        });
        const totalRow = result[result.length - 1];
        for (let i = 1; i < this.electricHeaders.length; i++) {
          const cellName = this.electricHeaders[i].value;
          totalRow[cellName].value = 0;
          for (let j = 0; j < result.length - 1; j++) {
            totalRow[cellName].value += result[j][cellName].value;
          }
          totalRow[cellName].value = isNaN(totalRow[cellName].value) ? null : totalRow[cellName].value;
        }
        totalRow.unitType.value = this.electricModel.unitType;

        return result;
      },
      set(value) {
        this.electricModel.table = value;
        this.mutateEnergyUsageDetails(this.electricModel);
      },
    },
    gasItems: {
      get() {
        return this.calculateTableData(
          this.gasModel.table,
          this.gasHeaders,
          this.gasModel.unitType,
          UNITS.gas[this.gasModel.unitType],
          'Gas Total'
        );
      },
      set(value) {
        this.gasModel.table = value;
        this.mutateEnergyUsageDetails(this.gasModel);
      },
    },
    steamItems: {
      get() {
        return this.calculateTableData(
          this.steamModel.table,
          this.steamHeaders,
          this.steamModel.unitType,
          UNITS.steam[this.steamModel.unitType],
          'Steam Total'
        );
      },
      set(value) {
        this.steamModel.table = value;
        this.mutateEnergyUsageDetails(this.steamModel);
      },
    },
    fuelOilItems: {
      get() {
        return this.calculateTableData(
          this.fuelOilModel.table,
          this.fuelOilHeaders,
          this.fuelOilModel.unitType,
          UNITS.fuelOil[this.fuelOilModel.unitType],
          'Fuel Oil Total'
        );
      },
      set(value) {
        this.fuelOilModel.table = value;
        this.mutateEnergyUsageDetails(this.fuelOilModel);
      },
    },
    propaneItems: {
      get() {
        return this.calculateTableData(
          this.propaneModel.table,
          this.propaneHeaders,
          this.propaneModel.unitType,
          UNITS.propane[this.propaneModel.unitType],
          'Propane Total'
        );
      },
      set(value) {
        this.propaneModel.table = value;
        this.mutateEnergyUsageDetails(this.propaneModel);
      },
    },
    combinedTotalItems() {
      const result = this.generateEmptyTable(this.combinedTotalHeaders, 'Energy Usage Total');
      result.forEach((row, index) => {
        row.kBTU.value = this.getTotalKBTU(this.getTotalFromTables(index, 'btu'));
        row.combinedCost.value = this.getTotalFromTables(index, 'totalCost');
        row.square.value = this.selectedSquare;
        row.eui.value = this.getTotalEUI(row.kBTU.value, row.square.value);
        row.squarePrice.value = this.getTotalSquarePrice(row.combinedCost.value, row.square.value);
        if (!row.kBTU.value || !row.combinedCost.value) {
          // it means, that for this month some data isn't filled
          row.month.value = row.month.value + ' *';
        }
      });
      return result;
    },
    electricHeaders() {
      return [
        {
          text: 'Month',
          value: 'month',
          width: '120px',
        },
        {
          text: 'Quantity',
          value: 'usage',
          width: '120px',
          editable: true,
        },
        {
          text: 'Unit Type',
          value: 'unitType',
          width: '120px',
        },
        {
          text: 'BTUs',
          value: 'btu',
          width: '120px',
        },
        {
          text: 'Demand Usage (kW)',
          value: 'demandUsage',
          width: '120px',
          editable: true,
          fixedNumber: 3,
        },
        {
          text: 'Demand Rate ($/kW)',
          value: 'demandUnitRate',
          width: '120px',
          editable: true,
          fixedNumber: 3,
        },
        {
          text: 'Demand Cost',
          value: 'demandCost',
          width: '120px',
          currency: true,
        },
        {
          text: 'Unit Rate ($/kWh)',
          value: 'unitRate',
          width: '120px',
          editable: true,
          fixedNumber: 3,
        },
        {
          text: 'Base Electric Consumption Cost',
          value: 'baseConsumptionCost',
          width: '120px',
          currency: true,
        },
        {
          text: 'Other Charges',
          value: 'otherCharges',
          width: '120px',
          editable: true,
        },
        {
          text: 'Total Electric Cost',
          value: 'totalCost',
          width: '120px',
          currency: true,
        },
      ];
    },
    gasHeaders() {
      return [
        {
          text: 'Month',
          value: 'month',
          width: '120px',
        },
        {
          text: 'Quantity',
          value: 'usage',
          width: '120px',
          editable: true,
        },
        {
          text: 'Unit Type',
          value: 'unitType',
          width: '120px',
          items: getUnitTypeList(UNITS.gas),
          itemValue: this.gasModel.unitType,
        },
        {
          text: 'BTUs',
          value: 'btu',
          width: '120px',
        },
        {
          text: 'Unit Rate',
          value: 'unitRate',
          width: '120px',
          editable: true,
        },
        {
          text: 'Gas Cost',
          value: 'demandCost',
          width: '120px',
          currency: true,
        },
        {
          text: 'Other Charges',
          value: 'otherCharges',
          width: '120px',
          editable: true,
        },
        {
          text: 'Total Gas Cost',
          value: 'totalCost',
          width: '120px',
          currency: true,
        },
      ];
    },
    steamHeaders() {
      return [
        {
          text: 'Month',
          value: 'month',
          width: '120px',
        },
        {
          text: 'Quantity',
          value: 'usage',
          width: '120px',
          editable: true,
        },
        {
          text: 'Unit Type',
          value: 'unitType',
          items: getUnitTypeList(UNITS.steam),
          itemValue: this.steamModel.unitType,
          width: '120px',
        },
        {
          text: 'BTUs',
          value: 'btu',
          width: '120px',
        },
        {
          text: 'Unit Rate',
          value: 'unitRate',
          width: '120px',
          editable: true,
        },
        {
          text: 'Steam Cost',
          value: 'demandCost',
          width: '120px',
          currency: true,
        },
        {
          text: 'Other Charges',
          value: 'otherCharges',
          width: '120px',
          editable: true,
        },
        {
          text: 'Total Steam Cost',
          value: 'totalCost',
          width: '120px',
          currency: true,
        },
      ];
    },
    fuelOilHeaders() {
      return [
        {
          text: 'Month',
          value: 'month',
          width: '120px',
        },
        {
          text: 'Quantity',
          value: 'usage',
          width: '120px',
          editable: true,
        },
        {
          text: 'Fuel Type',
          value: 'unitType',
          items: getUnitTypeList(UNITS.fuelOil),
          itemValue: this.fuelOilModel.unitType,
          width: '120px',
        },
        {
          text: 'BTUs',
          value: 'btu',
          width: '120px',
        },
        {
          text: 'Unit Rate',
          value: 'unitRate',
          width: '120px',
          editable: true,
        },
        {
          text: 'Fuel Oil Cost',
          value: 'demandCost',
          width: '120px',
          currency: true,
        },
        {
          text: 'Other Charges',
          value: 'otherCharges',
          width: '120px',
          editable: true,
        },
        {
          text: 'Total Fuel Oil Cost',
          value: 'totalCost',
          width: '120px',
          currency: true,
        },
      ];
    },
    propaneHeaders() {
      return [
        {
          text: 'Month',
          value: 'month',
          width: '120px',
        },
        {
          text: 'Quantity',
          value: 'usage',
          width: '120px',
          editable: true,
        },
        {
          text: 'Unit Type',
          value: 'unitType',
          items: getUnitTypeList(UNITS.propane),
          itemValue: this.propaneModel.unitType,
          width: '120px',
        },
        {
          text: 'BTUs',
          value: 'btu',
          width: '120px',
        },
        {
          text: 'Unit Rate',
          value: 'unitRate',
          width: '120px',
          editable: true,
        },
        {
          text: 'Propane Cost',
          value: 'demandCost',
          width: '120px',
          currency: true,
        },
        {
          text: 'Other Charges',
          value: 'otherCharges',
          width: '120px',
          editable: true,
        },
        {
          text: 'Total Propane Cost',
          value: 'totalCost',
          width: '120px',
          currency: true,
        },
      ];
    },
    combinedTotalHeaders() {
      return [
        {
          text: 'Month',
          value: 'month',
          width: '120px',
        },
        {
          text: 'Total kBTUs',
          value: 'kBTU',
          width: '120px',
        },
        {
          text: 'Total Combined Cost',
          value: 'combinedCost',
          width: '120px',
          currency: true,
        },
        {
          text: 'Square Footage',
          value: 'square',
          width: '120px',
        },
        {
          text: 'EUI/month',
          value: 'eui',
          width: '120px',
        },
        {
          text: '$/Sqft',
          value: 'squarePrice',
          width: '120px',
        },
      ];
    },
    statusList() {
      return [
        {
          label: 'Electricity',
          check: this.checkIsFilled(this.electricModel.table),
        },
        {
          label: 'Gas',
          check: this.checkIsFilled(this.gasModel.table),
        },
        {
          label: 'Steam',
          check: this.checkIsFilled(this.steamModel.table),
        },
        {
          label: 'Fuel Oil',
          check: this.checkIsFilled(this.fuelOilModel.table),
        },
        {
          label: 'Propane',
          check: this.checkIsFilled(this.propaneModel.table),
        },
      ];
    },
  },
  methods: {
    calculateTableData(tableModel, headers, unitType, unitTypeValue, totalTitle) {
      let result = tableModel;
      if (!result) {
        result = this.generateEmptyTable(headers, totalTitle);
      }
      result.forEach(row => {
        headers.forEach(header => {
          switch (header.value) {
            case 'unitType':
              row.unitType.value = unitType;
              break;
            case 'btu':
              row.btu.value = this.getBTU(row.usage.value, unitTypeValue);
              break;
            case 'demandCost':
              row.demandCost.value = this.getCost(row.usage.value, row.unitRate.value);
              break;
            case 'totalCost':
              row.totalCost.value = this.getTotalCost(row.demandCost.value, row.otherCharges.value);
              break;
          }
        });
      });
      const totalRow = result[result.length - 1];
      for (let i = 1; i < headers.length; i++) {
        const cellName = headers[i].value;
        totalRow[cellName].value = 0;
        for (let j = 0; j < result.length - 1; j++) {
          totalRow[cellName].value += result[j][cellName].value;
        }
        totalRow[cellName].value = isNaN(totalRow[cellName].value) ? null : totalRow[cellName].value;
      }
      totalRow.unitType.value = unitType;

      return result;
    },
    checkIsFilled(usageTable) {
      if (usageTable) {
        const totalRow = usageTable[usageTable.length - 1];
        let sum = 0;
        Object.values(totalRow).forEach(cell => (sum += typeof cell.value === 'number' ? cell.value : 0));
        return Boolean(sum);
      }
      return false;
    },
    generateEmptyTable(headers, totalTitle) {
      const columns = headers.reduce((accum, item) => ({ ...accum, [item.value]: { value: null } }), {});
      return MONTHS.concat([totalTitle]).map(month => {
        const result = JSON.parse(JSON.stringify({ ...columns }));
        result.month.value = month;
        return result;
      });
    },
    getFlatTable(table) {
      return table.map(row => {
        let result = {};
        for (let cellName in row) {
          result[cellName] = row[cellName].value;
        }
        return result;
      });
    },
    getTotalFromTables(index, prop) {
      const tables = [
        this.electricModel.table,
        this.gasModel.table,
        this.steamModel.table,
        this.fuelOilModel.table,
        this.propaneModel.table,
      ];
      let result;
      try {
        result = tables.reduce((accum, table) => {
          const value = table ? table[index][prop].value : 0;
          return accum + value;
        }, 0);
      } catch (e) {
        console.warn('Generate Total Table error:' + e);
        result = 0;
      }
      return result;
    },
    getBTU(usage, unitType) {
      return usage * unitType ?? null;
    },
    getCost(usage, unitRate) {
      return usage * unitRate ?? null;
    },
    getDemandCost(demandUsage, demandUnitRate) {
      return demandUsage * demandUnitRate ?? null;
    },
    getTotalCost(cost, otherCharges) {
      return cost + otherCharges ?? null;
    },
    getTotalElectricCost(demandCost, cost, otherCharges) {
      if (typeof demandCost !== 'number' && typeof cost !== 'number' && typeof otherCharges !== 'number') {
        return null;
      }
      return (demandCost || 0) + (cost || 0) + (otherCharges || 0);
    },
    getTotalKBTU(monthBTU) {
      const result = monthBTU / 1000;
      return isNaN(result) ? 0 : parseFloat(result.toFixed(2));
    },
    getTotalEUI(monthKBTU, sqrt) {
      const result = monthKBTU / sqrt;
      return isNaN(result) ? 0 : parseFloat(result.toFixed(2));
    },
    getTotalSquarePrice(totalCombinedCost, sqrt) {
      const result = totalCombinedCost / sqrt;
      return isNaN(result) ? 0 : parseFloat(result.toFixed(2));
    },
    mutateEnergyUsageDetails(model) {
      clearTimeout(updateTimeout);
      updateTimeout = setTimeout(() => {
        const input = {
          facilityId: this.selectedFacility,
          energyType: model.type,
          year: this.selectedYear.toString(),
          ratesChart: JSON.stringify(this.getFlatTable(model.table)),
        };
        if (!model.id) {
          this.$apollo
            .mutate({
              mutation: ADD_ENERGY_USAGE,
              variables: {
                input: input,
              },
              update: (store, { data: { addEnergyUsage } }) => {
                model.id = addEnergyUsage.id;
              },
            })
            .catch(e => {
              eventHub.$emit('show-snackbar', { color: 'error', text: errorFilter(e.message) });
            });
        } else {
          this.$apollo
            .mutate({
              mutation: UPDATE_ENERGY_USAGE,
              variables: {
                input: {
                  id: model.id,
                  ...input,
                },
              },
            })
            .catch(e => {
              eventHub.$emit('show-snackbar', { color: 'error', text: errorFilter(e.message) });
            });
        }
      }, 300);

      return false;
    },
    openModal() {
      this.$refs.energy_usage_modal.modalOpen = true;
    },
    onCloseModal() {
      this.$refs.energy_usage_modal && (this.$refs.energy_usage_modal.modalOpen = false);
    },
    onTableUnitChange(model, value) {
      model.unitType = value.data;
      this.$nextTick(() => {
        this.mutateEnergyUsageDetails(model);
      });
    },
    parseTableFromQuery(energyData) {
      const result = {
        table: null,
        id: null,
      };
      if (energyData) {
        try {
          result.table = JSON.parse(energyData.ratesChart);
          result.table = result.table.map(row => {
            const newRow = {};
            for (let cellName in row) {
              newRow[cellName] = {
                value: row[cellName],
              };
            }
            return newRow;
          });
          result.id = energyData.id;
        } catch (e) {
          console.warn('Parse Energy Usage Details error: ' + e);
        }
      }
      return result;
    },
    setDefaultTableValues() {
      const tableModels = generateDefaultEnergyModels();
      for (let tableModelName in tableModels) {
        this[tableModelName] = tableModels[tableModelName];
      }
    },
  },
};
