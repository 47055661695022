import Btn from '@/components/Btn/Btn.vue';
import InfoList from '../InfoList/InfoList.vue';
import Modal from '@/components/Modal/Modal.vue';
import ClientGeneralInfoForm from '@/components/ClientGeneralInfoForm/ClientGeneralInfoForm.vue';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';
import { getDisplayedData } from '@/utils/displayedData';
export default {
  name: 'ClientInfo',
  components: { Btn, InfoList, Modal, ClientGeneralInfoForm },
  props: {
    client: { type: Object, default: null },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-first',
    },
  },
  data: () => ({
    currentUser: null,
    error: null,
    selectedTab: null,
    generalInfoLabels: [
      {
        label: 'Name',
        value: 'name',
      },
      {
        label: 'District/Department Number',
        value: 'district',
      },
      {
        label: 'Client Type',
        value: 'clientType.name',
      },
      {
        label: 'Client Summary',
        value: 'description',
        type: 'longstring',
      },
    ],
    countryLabels: [
      {
        label: 'Address',
        value: 'address',
      },
      {
        label: 'City',
        value: 'city',
      },
      {
        label: 'State',
        value: 'state',
      },
      {
        label: 'Zip Code',
        value: 'zip',
      },
      {
        label: 'County',
        value: 'county',
      },
    ],
    icsLabels: [
      {
        label: 'ICS Project #',
        value: 'icsProjectNumber',
      },
      {
        label: 'ICS Responsible Office',
        value: 'icsOffice.name',
      },
      {
        label: 'Date of Creation',
        value: 'createdAt',
        type: 'utcDate',
      },
      {
        label: 'Date of Asessment',
        value: 'dateOfAssessment',
        type: 'utcDate',
      },
    ],
  }),
  computed: {
    showEditClient() {
      return accessByPermissions(PERMISSIONS.EDIT_CLIENT, this.currentUser);
    },
    displayedGeneralInfo() {
      return getDisplayedData(this.generalInfoLabels, this.client);
    },
    displayedCounryInfo() {
      return getDisplayedData(this.countryLabels, this.client);
    },
    displayedICSInfo() {
      return getDisplayedData(this.icsLabels, this.client);
    },
  },
};
