import { VDataTable } from 'vuetify/lib';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import Btn from '@/components/Btn/Btn.vue';
import TruncatedTextSimple from '@/components/TruncatedTextSimple/TruncatedTextSimple.vue';
import DataTable from '@/components/DataTable/DataTable.vue';

const vsStep = 20;
const vsTopOffsetIndex = 10;
const vsMaxCacheSize = 50;
const vsRowHeight = 48;
let lastCachedTopIndex = 0;
let lastCachedBottomIndex = vsStep;
let lastVsMaxIndex = vsStep;
export default {
  name: 'DataTableVs',
  extends: DataTable,
  components: {
    VDataTable,
    DatePicker,
    Btn,
    TruncatedTextSimple,
  },
  props: {
    enableVirtualScroll: { type: Boolean, default: true },
    noDataText: {
      type: String,
      default: 'No data available',
    },
  },
  data() {
    const currentPerPage = this.itemsPerPage || 10;
    return {
      vsMaxIndex: vsStep + vsTopOffsetIndex,
      vsMinIndex: 0,
      currentPerPage: currentPerPage,
      selectData: {},
      vsTopPadding: 0,
      vsBottomPadding: 0,
    };
  },
  watch: {
    value: {
      handler() {
        if (this.showSelect) {
          this.selectData = {};
          this.value.forEach(item => {
            item.id && (this.selectData[item.id] = true);
          });
        }
      },
    },
  },
  computed: {
    clearedItems() {
      return this.items;
    },
    isVsEnabled() {
      return this.enableVirtualScroll && this.currentPerPage === -1;
    },
    vsItems() {
      if (this.isVsEnabled) {
        const direction = this.getScrollDirection();
        if (direction >= 0) {
          const diff = this.vsMaxIndex - lastCachedTopIndex;
          if (diff >= vsMaxCacheSize) {
            lastCachedTopIndex = this.vsMinIndex;
            this.vsTopPadding = this.getVsTopPadding();
          }
          this.vsBottomPadding = this.getVsBottomPadding();
          lastCachedBottomIndex = this.vsMaxIndex;
        } else {
          const diff = Math.abs(this.vsMinIndex - lastCachedBottomIndex);
          if (diff >= vsMaxCacheSize || diff < vsStep) {
            lastCachedBottomIndex = this.vsMaxIndex;
            this.vsBottomPadding = this.getVsBottomPadding();
          }
          lastCachedTopIndex = this.vsMinIndex;
          this.vsTopPadding = this.getVsTopPadding();
        }
        if (lastCachedTopIndex > this.filteredItems.length) {
          lastCachedTopIndex = this.filteredItems.length - vsStep;
          lastCachedBottomIndex = this.filteredItems.length;
          this.vsBottomPadding = 0;
          this.vsTopPadding = this.getVsTopPadding();
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        }
        this.$nextTick(() => {
          clearTimeout(this.recalcHeaderStylesTimeout);
          this.recalcHeaderStylesTimeout = setTimeout(() => {
            this.recalcHeaderStyles();
          }, 100);
        });
        return this.filteredItems.slice(lastCachedTopIndex, lastCachedBottomIndex);
        // return this.filteredItems.slice(0, this.vsMaxIndex);
      }
      return this.filteredItems;
    },
    isVSFinished() {
      // return this.vsItems.length >= this.filteredItems.length;
      return this.vsMaxIndex >= this.filteredItems.length;
    },
    vsPaginationCurrent() {
      const length = this.filteredItems.length;
      return this.vsMaxIndex <= length ? this.vsMaxIndex : length;
    },
  },
  methods: {
    initScrollHandler() {
      if (this.$refs.table && this.$refs.table.$el) {
        this.tableWrapper = this.$refs.table.$el.querySelector('.v-data-table__wrapper');
        if (this.tableWrapper) {
          this.tableWrapper.addEventListener('scroll', this.hideFilterDropdown());
          if (this.enableVirtualScroll) {
            this.tableWrapper.addEventListener('scroll', this.virtualScrollHandler);
          }
        }
      }
    },
    getVsTopPadding() {
      return this.vsMinIndex * vsRowHeight + 'px';
    },
    getVsBottomPadding() {
      return (this.filteredItems.length - this.vsMaxIndex) * vsRowHeight + 'px';
    },
    getScrollDirection() {
      const diff = this.vsMaxIndex - lastVsMaxIndex;
      lastVsMaxIndex = this.vsMaxIndex;
      if (diff > 0) {
        return 1;
      }
      if (diff < 0) {
        return -1;
      }
      return 0;
    },
    virtualScrollHandler() {
      if (this.isVsEnabled) {
        this.vsTimeout && clearTimeout(this.vsTimeout);
        this.vsTimeout = setTimeout(() => {
          this.scrollByHeight();
        }, 20);
      }
    },
    scrollByHeight() {
      const containerHeight = this.tableWrapper.offsetHeight;
      const maxIndex = Math.ceil((this.tableWrapper.scrollTop + containerHeight) / vsRowHeight) + 4;
      const minIndex = maxIndex - vsStep - vsTopOffsetIndex;
      this.vsMinIndex = minIndex < 0 ? 0 : minIndex;
      maxIndex > vsStep ? (this.vsMaxIndex = maxIndex) : this.resetVS();
    },
    hideFilterDropdown() {
      let scrollStart = true;
      const func = () => {
        if (scrollStart) {
          this.tableWrapper.click();
          scrollStart = false;
        }
      };
      let timer;
      return () => {
        func();
        clearTimeout(timer);
        timer = setTimeout(() => {
          scrollStart = true;
        }, 100);
      };
    },
    scrollToBottom() {
      if (typeof this.tableWrapper.scrollTo === 'function') {
        this.tableWrapper.scrollTo(
          this.tableWrapper.scrollLeft,
          this.tableWrapper.scrollHeight + this.tableWrapper.offsetHeight
        );
      }
    },
    resetVS() {
      if (this.isVsEnabled) {
        this.vsMaxIndex = vsStep;
        this.vsMinIndex = 0;
        lastCachedTopIndex = 0;
        lastCachedBottomIndex = vsStep;
        this.vsTopPadding = 0;
        this.vsBottomPadding = this.getVsBottomPadding();
      }
    },
    resetScroll() {
      if (typeof this.tableWrapper.scrollTo === 'function') {
        this.tableWrapper.scrollTo(this.tableWrapper.scrollLeft, 0);
      }
    },
    reset(items) {
      this.updateFilters(true);
      this.sorting = null;
      this.filteredItems = [...items];
      this.lastSelectedFilter = null;
      this.resetScroll();
      this.resetVS();
    },
    onFilter(value) {
      clearTimeout(this.filterTimeout);
      this.filterTimeout = setTimeout(() => {
        this.lastSelectedFilter = value;
        this.resetVS();
        this.resetScroll();
        this.filter();
        this.sort();
        this.recalcHeaderStyles();
      }, 200);
    },
    onSorting(header) {
      if (this.groupBy && this.groupBy === header.value) {
        this.internalGroupDesc = !this.internalGroupDesc;
      } else {
        let order = 'asc';
        if (this.sorting && header.value === this.sorting.name) {
          order = this.sorting.order === 'asc' ? 'desc' : 'asc';
        }
        this.sorting = { name: header.value, order: order, type: header.dataType || header.type };
      }
      this.sort();
      this.resetScroll();
      this.resetVS();
    },
    onPerPageChange(perPage) {
      this.currentPerPage = perPage;
      this.resetScroll();
      this.resetVS();
      this.recalcHeaderStyles();
    },
    onSelectChange() {
      this.$emit(
        'input',
        Object.keys(this.selectData)
          .filter(item => this.selectData[item])
          .map(keyName => this.filteredItems.find(item => Number(item.id) === Number(keyName)))
      );
    },
    getItemClass(item) {
      const result = {
        'v-data-table__selected': this.selectData[item.id],
      };
      if (typeof this.itemClass === 'function') {
        result[this.itemClass(item)] = true;
      }
      return result;
    },
  },
};
