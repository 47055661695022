import Btn from '@/components/Btn/Btn.vue';
export default {
  name: 'TruncatedTextSimple',
  components: {
    Btn,
  },
  props: {
    text: { type: String },
  },
  mounted() {},
  beforeDestroy() {},
  data: () => ({
    showTooltip: false,
  }),
  methods: {
    checkTextHeight() {
      if (!this.showTooltip && this.$refs.originalText.offsetHeight > 42) {
        this.showTooltip = true;
      }
    },
  },
};
