const HEADERS_SELECTION = 'headers';
const FITLER_SELECTION = 'filters_v1';

export const setHeadersSelection = (id, items) => {
  setStorage(id, items, HEADERS_SELECTION);
};
export const getHeadersSelection = id => {
  return getStorage(id, HEADERS_SELECTION);
};
export const setFilters = (id, items) => {
  setStorage(id, items, FITLER_SELECTION, sessionStorage);
};
export const getFilters = id => {
  return getStorage(id, FITLER_SELECTION, sessionStorage);
};

const setStorage = (id, items, STORAGE_NAME, STORAGE_TYPE = localStorage) => {
  try {
    let storage = STORAGE_TYPE.getItem(STORAGE_NAME);
    storage = !storage ? {} : JSON.parse(storage);
    storage[id] = items;
    STORAGE_TYPE.setItem(STORAGE_NAME, JSON.stringify(storage));
  } catch (e) {
    console.warn(`set ${STORAGE_NAME} to Storage error`, e);
  }
};
const getStorage = (id, STORAGE_NAME, STORAGE_TYPE = localStorage) => {
  let parsed = {};
  try {
    parsed = JSON.parse(STORAGE_TYPE.getItem(STORAGE_NAME));
  } catch (e) {
    console.warn(`get ${STORAGE_NAME} from Storage error`, e);
  }
  return parsed && parsed[id];
};
