var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"title":_vm.modalTitle,"width":"380px"},on:{"open":function($event){_vm.name = ''}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("default",[_c('btn',_vm._g(_vm._b({staticClass:"mr-6",attrs:{"width":"110","color-type":"accent","disabled":_vm.disabled}},'btn',attrs,false),on),[_vm._v(_vm._s(_vm.btnTitle))])],{"on":on,"attrs":attrs})]}},{key:"default",fn:function(ref){
var close = ref.close;
return [_c('ApolloMutation',_vm._g({staticClass:"expenditure-options-modal",attrs:{"mutation":_vm.mutation,"variables":{ input: Object.assign({}, _vm.variables, {name: _vm.name}) },"update":_vm.downloadFile},on:{"done":close},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var mutate = ref.mutate;
var loading = ref.loading;
var error = ref.error;
return [(_vm.submitFormData)?_c('v-overlay',{attrs:{"z-index":10000,"value":_vm.submitFormData,"absolute":"","opacity":"0.2"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"30"}})],1)],1):_vm._e(),(!loading)?_c('alert',{attrs:{"align":"center","error":error}}):_vm._e(),_c('v-text-field',{attrs:{"label":"Report Name","rules":_vm.nameRules,"autofocus":"","autocomplete":"off","validate-on-blur":"","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('div',{staticClass:"text-center"},[_c('btn',{attrs:{"width":"200","disabled":_vm.isNameValid},on:{"click":function () {
                _vm.submitFormData = true;
                mutate(close);
              }}},[_vm._v(_vm._s(_vm.btnName))])],1)]}}],null,true)},_vm.$listeners))]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }