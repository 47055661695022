<template>
  <v-app>
    <app-layout>
      <app-notification></app-notification>
      <router-view />
    </app-layout>
  </v-app>
</template>

<script>
import AppLayout from '@/components/AppLayout/AppLayout.vue';
import AppNotification from '@/components/AppNotification/AppNotification.vue';

export default {
  name: 'App',
  components: {
    AppNotification,
    AppLayout,
  },
  data: () => {
    return {
      pageTitle: '',
    };
  },
  created() {
    this.updateTitle(this.$route);
  },
  watch: {
    $route(to) {
      this.updateTitle(to);
    },
  },
  methods: {
    updateTitle: function(route) {
      document.title = (route.meta && route.meta.title) || 'ICS';
      // this.pageTitle =
      // route.matched.find(m => m.meta.pageTitle).meta.pageTitle || "";
    },
  },
};
</script>
<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-shadow: none;
  text-rendering: optimizeLegibility;

  .v-label {
    font-size: inherit;
    height: 1.2em;
    line-height: 1.1em;
  }
  .v-menu__content .v-list-item.v-list-item--link {
    font-size: 0.875rem;
  }
  .v-select__selections {
    min-width: 28px !important;
  }
  .v-tooltip {
    z-index: 21;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  border: 0;
  -webkit-text-fill-color: var(--v-primary-base);
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  background-color: transparent !important;
}

h1 {
  font-size: 1.625rem;
}
h1,
h2,
h3,
.v-list-item--active {
  color: var(--v-primary-base);
}

.-position-relative {
  position: relative;
}
.-position-absolute {
  position: absolute;
}
.v-text-field input {
  line-height: 1.2;
}

.loader {
  position: relative;
  &:after {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: var(--v-background-base);
    content: '';
    z-index: 10000;
  }
  &:before {
    position: absolute;
    content: '';
    background-image: url('~@/assets/loading.svg');
    background-repeat: no-repeat no-repeat;
    background-position: center;
    background-size: 32px;
    left: 0;
    top: 0;
    z-index: 100001;
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 32px;
    justify-content: center;
    align-items: center;
  }
  &.-loaded {
    &:after,
    &:before {
      display: none;
    }
  }
}

.app-navigation-drawer.theme--light {
  .theme--light.v-list-item {
    color: #fff !important;

    .theme--light.v-icon {
      color: #fff !important;
    }
  }
  .theme--light.v-btn.v-btn--icon {
    color: #fff !important;
  }
}

.-cursor-pointer {
  cursor: pointer;
}

.-font-weight-600 {
  font-weight: 600;
}

.-width-100 {
  width: 100%;
}

@import './styles/dark-theme';
</style>
