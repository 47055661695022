import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import Btn from '@/components/Btn/Btn.vue';
import Modal from '@/components/Modal/Modal.vue';

export default {
  name: 'FullWithCard',
  components: { Btn, Modal },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    loading: { type: Boolean },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
  },
  data: () => ({
    wide: false,
  }),
  watch: {
    wide() {
      this.$refs.card_modal.modalOpen = this.wide;
    },
  },
  methods: {
    toggleWide() {
      this.wide = !this.wide;
    },
  },
};
