import Btn from '@/components/Btn/Btn.vue';
import PLAN_TYPES_QUERY from '@/graphql/queries/planTypes.gql';
import CLIENT_QUERY from '@/graphql/queries/client.gql';
import validateRules from '@/services/validateRules';
import ADD_FINANCIAL_INFO_MUTATION from '@/graphql/mutations/addFinancialInfo.gql';
import UPDATE_FINANCIAL_INFO_MUTATION from '@/graphql/mutations/updateFinancialInfo.gql';
import { isEqual } from 'lodash';
import eventHub from '@/utils/eventHub';
import NumberTextField from '@/components/NumberTextField/NumberTextField.vue';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import { generateRangeArray } from '@/utils/generator';

const copy = (obj1, obj2) =>
  Object.keys(obj1).reduce((a, key) => ({ ...a, [key]: obj2 !== null && obj2[key] !== undefined ? obj2[key] : null }), {});

export default {
  name: 'FinancialInfoForm',
  components: { Btn, DatePicker, NumberTextField },
  props: {
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    financialInfo: { type: Object },
    planId: { type: String },
    clientId: { type: String },
    readonlyFields: { type: Array },
    active: { type: Boolean, default: false },
  },
  apollo: {
    planTypes: {
      query: PLAN_TYPES_QUERY,
      fetchPolicy: 'cache-first',
      error(error) {
        this.error = error.networkError ? { ...error, message: 'Something went wrong! Could not load plan type list.' } : error;
      },
    },
  },
  data: () => ({
    ...validateRules,
    form: {
      typeOfPlan: null,
      planStartYear: null,
      regionalCost: null,
      fundingEscalation: null,
      inflationCostEscalation: null,
      softCostPercentage: null,
    },
    years: generateRangeArray(new Date().getFullYear() - 20, new Date().getFullYear() + 20).reverse(),
    planTypeRules: [v => !!v || 'Plan Type is Required'],
    planTypes: [],
    planStartYearRules: [v => !!v || 'Plan Start Year is Required'],
    regionalCostRules: [
      v => (v !== null && v !== '') || 'Regional Cost is Required',
      validateRules.numberInRange(0, 200),
      validateRules.integerNumber,
    ],
    fundingEscalationRules: [
      v => (v !== null && v !== '') || 'Funding Escalation is Required',
      validateRules.numberInRange(0, 100),
      validateRules.floatNumber(1),
    ],
    inflationCostEscalationRules: [
      v => (v !== null && v !== '') || 'Inflation cost escalation',
      validateRules.numberInRange(0, 100),
      validateRules.floatNumber(1),
    ],
    softCostPercentageRules: [
      v => (v !== null && v !== '') || 'Soft Cost Percentage is Required',
      validateRules.numberInRange(0, 100),
      validateRules.floatNumber(1),
    ],
    internalLoading: false,
    error: null,
    changes: new Set(),
  }),
  watch: {
    financialInfo: {
      immediate: true,
      handler(value) {
        if (value) {
          this.error = null;
          this.form = copy(this.form, value);
          this.changes.clear();
        } else {
          this.changes = new Set('regionalCost', 'fundingEscalation', 'inflationCostEscalation', 'softCostPercentage');
          this.form = {
            ...this.form,
            regionalCost: 100,
            fundingEscalation: 0,
            inflationCostEscalation: 0,
            softCostPercentage: 0,
          };
          if (this.active) {
            this.$emit('change');
          }
        }
      },
    },
    active: {
      immediate: true,
      handler(val) {
        if (val && this.changes.size > 0) {
          this.$emit('change');
        } else {
          this.$emit('reset');
        }
      },
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    onChange(key, value) {
      if (this.financialInfo) {
        if (!isEqual(this.financialInfo[key], value)) {
          this.changes.add(key);
        } else if (this.changes.has(key)) {
          this.changes.delete(key);
        }
      } else {
        this.changes.add(key);
      }
      if (this.active) {
        this.$emit('change');
      }
    },
    onSubmit() {
      this.error = null;
      if (this.validate() && this.changes.size > 0) {
        const { typeOfPlan, planStartYear, regionalCost, inflationCostEscalation, softCostPercentage } = this.form;
        let variables = {
          typeOfPlanId: (typeOfPlan && typeOfPlan.id) || null,
          planStartYear: planStartYear,
          regionalCost,
          inflationCostEscalation,
          softCostPercentage,
        };
        if (!this.financialInfo || !this.financialInfo.id) {
          this.addFinancialInfo({ ...variables, planId: this.planId });
        } else {
          this.updateFinancialInfo({ ...variables, id: this.financialInfo.id });
        }
      } else if (this.validate() && this.changes.size === 0) {
        this.$emit('done');
      }
    },
    updateStoreFinancialInfo(store, { data: { updateFinancialInfo, addFinancialInfo } }) {
      let data = store.readQuery({
        query: CLIENT_QUERY,
        variables: { id: this.clientId || null },
      });
      let newData = updateFinancialInfo ? updateFinancialInfo : addFinancialInfo;
      data.client.plans[0].financialInfo = Object.assign({}, data.client.plans[0].financialInfo, newData);
      store.writeQuery({
        query: CLIENT_QUERY,
        variables: { id: this.clientId || null },
        data: data,
      });
    },
    addFinancialInfo(variables) {
      this.internalLoading = true;
      this.$apollo
        .mutate({
          mutation: ADD_FINANCIAL_INFO_MUTATION,
          variables: {
            input: variables,
          },
          update: this.updateStoreFinancialInfo,
        })
        .then(() => {
          eventHub.$emit('show-snackbar', {
            color: 'success',
            text: `Financial was successfully updated!`,
          });
          this.$emit('done');
        })
        .catch(e => {
          this.error = e;
        })
        .finally(() => {
          this.internalLoading = false;
        });
    },
    updateFinancialInfo(variables) {
      this.internalLoading = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_FINANCIAL_INFO_MUTATION,
          variables: {
            input: variables,
          },
          update: this.updateStoreFinancialInfo,
        })
        .then(() => {
          eventHub.$emit('show-snackbar', {
            color: 'success',
            text: `Financial info was updated successfully!`,
          });
          this.$emit('done');
        })
        .catch(e => {
          this.error = e;
        })
        .finally(() => {
          this.internalLoading = false;
        });
    },
  },
};
