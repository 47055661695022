import Btn from '@/components/Btn/Btn.vue';
import Modal from '@/components/Modal/Modal.vue';
import ConfirmationModal from '@/components/ConfirmationModal/ConfirmationModal.vue';
import ExpansionPanelHeader from '@/components/ExpansionPanelHeader/ExpansionPanelHeader.vue';
import DataTable from '@/components/DataTable/DataTable.vue';
import FundingSourceForm from './FundingSourceForm/FundingSourceForm.vue';
import InfusionSourceForm from './InfusionSourceForm/InfusionSourceForm.vue';
import CLIENT_QUERY from '@/graphql/queries/client.gql';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import COMPONENTS_OF_NEED_BY_FUNDING_SOURCE from '@/graphql/queries/componentsOfNeedByFundingSource.gql';
import DELETE_FUNDING_SOURCE_MUTATION from '@/graphql/mutations/deleteFundingSource.gql';
import DELETE_CAPITAL_INFUSION_MUTATION from '@/graphql/mutations/deleteCapitalInfusions.gql';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';
import eventHub from '@/utils/eventHub';

export default {
  name: 'FinancialInfoTables',
  props: {
    fundingSources: { type: Array, default: [] },
    planId: { type: String },
    clientId: { type: String },
    financialInfoId: { type: String },
    planStartYear: { type: String },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-first',
    },
  },
  data: vm => ({
    COMPONENTS_OF_NEED_BY_FUNDING_SOURCE,
    DELETE_FUNDING_SOURCE_MUTATION,
    DELETE_CAPITAL_INFUSION_MUTATION,
    fundingHeaders: [
      {
        text: 'Funding Source Name',
        value: 'name',
        type: 'text',
        dataType: 'string',
        width: '409px',
        fixed: true,
      },
      { text: 'Revenue', value: 'capitalFundingAmount', type: 'number', dataType: 'currency', width: '409px' },
      {
        text: 'Funding Escalation',
        value: 'fundingEscalation',
        type: 'number',
        dataType: 'percentage',
        width: '194px',
      },
      ...(!vm.readonly
        ? [
            {
              text: 'Actions',
              value: 'actions',
              sortable: false,
              width: '53px',
              fixed: true,
              permissions: [PERMISSIONS.EDIT_FINANCIAL],
            },
          ]
        : []),
    ],
    infusionHeaders: [
      {
        text: 'Capital Infusion Funding Source',
        value: 'fundingName',
        type: 'text',
        dataType: 'string',
        width: '409px',
        fixed: true,
      },
      {
        text: 'Capital Infusion Amount',
        value: 'capitalInfusionAmount',
        type: 'number',
        dataType: 'currency',
        width: '409px',
      },
      { text: 'Capital Infusion Year', value: 'capitalInfusionYear', type: 'number', dataType: 'year', width: '194px' },
      ...(!vm.readonly
        ? [
            {
              text: 'Actions',
              value: 'actions',
              sortable: false,
              width: '53px',
              fixed: true,
              permissions: [PERMISSIONS.EDIT_FINANCIAL],
            },
          ]
        : []),
    ],
    expanded: [],
    loadingConfirmation: true,
    confirmResult: {},
  }),
  components: {
    Modal,
    Btn,
    ExpansionPanelHeader,
    DataTable,
    ConfirmationModal,
    FundingSourceForm,
    InfusionSourceForm,
  },
  methods: {
    onDeleteFunding(item) {
      return (store, { data: { deleteFundingSource } }) => {
        if (deleteFundingSource) {
          let data = store.readQuery({
            query: CLIENT_QUERY,
            variables: { id: this.clientId || null },
          });
          data.client.plans[0].financialInfo.fundingSources = this.fundingSources.filter(source => {
            return item.id !== source.id;
          });
          store.writeQuery({
            query: CLIENT_QUERY,
            variables: { id: this.clientId || null },
            data: data,
          });
          eventHub.$emit('show-snackbar', { color: 'success', text: 'Funding Source was deleted successfully!' });
          eventHub.$emit('refresh-components-of-need');
        }
      };
    },
    onDeleteInfusion(item) {
      return (store, { data: { deleteCapitalInfusions } }) => {
        if (deleteCapitalInfusions) {
          let data = store.readQuery({
            query: CLIENT_QUERY,
            variables: { id: this.clientId || null },
          });
          data.client.plans[0].financialInfo.fundingSources = data.client.plans[0].financialInfo.fundingSources.map(source => {
            let newSource = source;
            if (source.capitalInfusions?.find(infusion => item.id === infusion.id)) {
              newSource.capitalInfusions = source.capitalInfusions?.filter(infusion => item.id !== infusion.id);
            }
            return newSource;
          });
          store.writeQuery({
            query: CLIENT_QUERY,
            variables: { id: this.clientId || null },
            data: data,
          });
          eventHub.$emit('show-snackbar', { color: 'success', text: 'Funding Source was deleted successfully!' });
        }
      };
    },
    onModalClose() {
      this.loadingConfirmation = true;
      this.confirmResult = {};
    },
    onQueryFinish(result) {
      this.loadingConfirmation = false;
      this.confirmResult = result;
    },
  },
  computed: {
    displayedFundingHeaders() {
      return this.fundingHeaders.filter(h => !h.permissions || h.permissions.some(p => accessByPermissions(p, this.currentUser)));
    },
    displayedInfusionHeaders() {
      return this.infusionHeaders.filter(h => !h.permissions || h.permissions.some(p => accessByPermissions(p, this.currentUser)));
    },
    displayedInfusionItems() {
      let dataItems = [];
      this.fundingSources.forEach(source => {
        dataItems.push(
          ...source.capitalInfusions.map(item => {
            let newItem = item;
            newItem.fundingName = source.name;
            return newItem;
          })
        );
      });
      return dataItems;
    },
    showAddBtn() {
      return accessByPermissions(PERMISSIONS.EDIT_FINANCIAL, this.currentUser);
    },
    showEditBtn() {
      return accessByPermissions(PERMISSIONS.EDIT_FINANCIAL, this.currentUser);
    },
    showDeleteBtn() {
      return accessByPermissions(PERMISSIONS.EDIT_FINANCIAL, this.currentUser);
    },
  },
};
