import Btn from '@/components/Btn/Btn.vue';
import { COMPONENTS_REPORT_TYPE, COMPONENTS_REPORT_TYPE_LABELS } from '@/enums/componentsReportType';
import { sortByName } from '@/utils/sortByName';
import NumberTextField from '@/components/NumberTextField/NumberTextField.vue';

export default {
  name: 'ComponentsReportForm',
  components: { Btn, NumberTextField },
  props: {
    loading: { type: Boolean, default: false },
    reportInfo: {
      type: Object,
      default: {
        name: null,
        reports: null,
        startYear: '',
        endYear: '',
      },
    },
    reportStartYear: {
      type: Number,
      default: 1970,
    },
    disableYearSlider: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      reportTypes: [
        {
          name: COMPONENTS_REPORT_TYPE_LABELS[COMPONENTS_REPORT_TYPE.EXPENDITURE_SPREADSHEET],
          value: COMPONENTS_REPORT_TYPE.EXPENDITURE_SPREADSHEET,
        },
        {
          name: COMPONENTS_REPORT_TYPE_LABELS[COMPONENTS_REPORT_TYPE.SUMMARY_BY_FACILITY],
          value: COMPONENTS_REPORT_TYPE.SUMMARY_BY_FACILITY,
        },
        {
          name: COMPONENTS_REPORT_TYPE_LABELS[COMPONENTS_REPORT_TYPE.SUMMARY_BY_FISCAL_YEAR],
          value: COMPONENTS_REPORT_TYPE.SUMMARY_BY_FISCAL_YEAR,
        },
        {
          name: COMPONENTS_REPORT_TYPE_LABELS[COMPONENTS_REPORT_TYPE.DETAILED_SUMMARY_OF_FACILITY_COSTS],
          value: COMPONENTS_REPORT_TYPE.DETAILED_SUMMARY_OF_FACILITY_COSTS,
        },
        {
          name: COMPONENTS_REPORT_TYPE_LABELS[COMPONENTS_REPORT_TYPE.ITEMIZED_LIST_OF_FACILITY_COSTS],
          value: COMPONENTS_REPORT_TYPE.ITEMIZED_LIST_OF_FACILITY_COSTS,
        },
        {
          name: COMPONENTS_REPORT_TYPE_LABELS[COMPONENTS_REPORT_TYPE.GAPS_ANALYSIS],
          value: COMPONENTS_REPORT_TYPE.GAPS_ANALYSIS,
        },
        {
          name: COMPONENTS_REPORT_TYPE_LABELS[COMPONENTS_REPORT_TYPE.SUMMARY_BY_FUNDING_SOURCE],
          value: COMPONENTS_REPORT_TYPE.SUMMARY_BY_FUNDING_SOURCE,
        },
      ].sort(sortByName),
      nameRules: [v => !!v || 'Report Name is Required'],
      reportsRules: [v => !!v || 'Report Type is Required'],
      reportYearRange: [0, 0],
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    onRangeChange() {
      this.reportInfo.startYear = this.reportYearRange[0].toString();
      this.reportInfo.endYear = this.reportYearRange[1].toString();
    },
    onSubmit() {
      if (this.validate()) {
        this.$emit('submit');
      }
    },
  },
  computed: {
    minReportYear() {
      return this.reportStartYear;
    },
    maxReportYear() {
      return this.reportStartYear + 20;
    },
    isGenerateDisable() {
      return !this.reportInfo.reports.length || this.loading;
    },
  },
  watch: {
    reportStartYear: {
      handler(newVal) {
        this.reportYearRange = [newVal, newVal + 9];
        this.reportInfo.startYear = this.reportYearRange[0].toString();
        this.reportInfo.endYear = this.reportYearRange[1].toString();
      },
      immediate: true,
    },
  },
};
