import SIGNIN_MUTATION from '@/graphql/mutations/signin.gql';
import { onLogin } from '@/apollo/apollo';
import validateRules from '@/services/validateRules';
import { VBtn } from 'vuetify/lib';
export default {
  name: 'Login',
  components: { VBtn },
  data: () => ({
    SIGNIN_MUTATION,
    password: '',
    passwordRules: [v => !!v || 'Password is Required'],
    email: null,
    emailRules: [v => !!v || 'Email is Required', validateRules.email],
    timer: null,
    counter: 0,
  }),
  mounted() {
    this.timer = setInterval(() => {
      this.counter++;
      if (this.$refs.form.$el.querySelectorAll('input:-webkit-autofill').length > 0) {
        this.$refs.form.$el.querySelectorAll('input').forEach(el => el.classList.add('v-input--is-label-active'));
        this.$refs.form.$el.querySelectorAll('label').forEach(el => el.classList.add('v-label--active'));
        clearInterval(this.timer);
      }
      if (this.counter > 20) {
        clearInterval(this.timer);
      }
    }, 100);
  },
  computed: {
    btnColor() {
      console.log('theme', this.$vuetify.theme);
      return this.$vuetify.theme.isDark ? 'accent' : 'primary';
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onDone: async function(value) {
      const {
        data: {
          signIn: { accessToken, refreshToken },
        },
      } = value;
      await onLogin(this.$apolloProvider.defaultClient, accessToken, refreshToken);
      //onComplete handler added to avoid js issue with replacing url
      await this.$router.push('/', () => {});
    },
  },
};
