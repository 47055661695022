import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import UPDATE_FACILITY_MUTATION from '@/graphql/mutations/updateFacility.gql';
import PERMISSIONS from '@/enums/permissions';
import accessByPermissions from '@/services/userPermissions';
import Btn from '@/components/Btn/Btn.vue';
import DataTable from '@/components/DataTable/DataTable.vue';
import ComparisonGrid from './ComparisonGrid/ComparisonGrid.vue';
import eventHub from '@/utils/eventHub';
import EnergyUsageDetails from './EnergyUsageDetails/EnergyUsageDetails.vue';
import { errorFilter } from '@/filters/errorMsg';

export default {
  name: 'EnergyUsage',
  components: { Btn, ComparisonGrid, DataTable, EnergyUsageDetails },
  props: {
    client: { type: Object, default: null },
    plan: { type: Object, default: null },
    facilities: { type: Array, default: () => [] },
    loading: { type: Boolean },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
  },
  data: vm => ({
    tab: vm.activeTab,
    currentUser: null,
    headers: [
      {
        text: 'Facility Name',
        value: 'name',
        type: 'text',
        dataType: 'string',
        width: '310px',
      },
      { text: 'Age(s) of Facility', value: 'age', type: 'text', dataType: 'string', width: '114px' },
      { text: 'Type', value: 'type', type: 'text', dataType: 'string', width: '235px' },
      { text: 'Square Footage', value: 'squareFootage', type: 'number', dataType: 'number', width: '135px' },
      { text: 'Notes', value: 'energyUsageNotes', type: 'text', dataType: 'longstring', width: '310px' },
    ],
    loadingConfirmation: true,
    confirmResult: {},
    editableField: '',
    valid: true,
    selectedFacilityId: null,
  }),
  computed: {
    displayedItems() {
      return this.facilities;
    },
    displayedHeaders() {
      return this.headers.filter(h => !h.permissions || h.permissions.some(p => accessByPermissions(p, this.currentUser)));
    },
    showEditBtn() {
      return accessByPermissions(PERMISSIONS.EDIT_FACILITY, this.currentUser);
    },
    showDeleteBtn() {
      return accessByPermissions(PERMISSIONS.DELETE_FACILITY, this.currentUser);
    },
    planStartYear() {
      return Number(this.plan?.financialInfo?.planStartYear);
    },
  },
  methods: {
    openEnergyUsageModal(item) {
      this.selectedFacilityId = item.id;
      this.$refs.energy_usage_modal.openModal();
    },
    onNotesOpen(item) {
      setTimeout(() => {
        this.editableField = item.energyUsageNotes;
      }, 100);
    },
    saveNotes(item) {
      item.energyUsageNotes = this.editableField;
      if (!this.valid) return;
      this.saveFacility(item);
    },
    saveFacility(item) {
      if (!this.valid) return;
      const input = {
        id: item.id,
        energyUsageNotes: item.energyUsageNotes,
      };
      this.$apollo
        .mutate({
          mutation: UPDATE_FACILITY_MUTATION,
          variables: {
            input,
          },
        })
        .then(() => {
          eventHub.$emit('show-snackbar', { color: 'success', text: `Facility ${item.componentName} is updated!` });
        })
        .catch(e => {
          eventHub.$emit('show-snackbar', { color: 'error', text: errorFilter(e.message) });
        });
      return false;
    },
  },
};
