var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({ref:"table",staticClass:"data-table",attrs:{"items":_vm.filteredItems,"hideDefaultHeader":"","fixed-header":"","mobile-breakpoint":"0","group-desc":_vm.internalGroupDesc,"headers":_vm.$props.headers.map(function (h) { return (Object.assign({}, h, {cellClass: h.fixed ? 'fixed' : ''})); }),"footer-props":_vm.$props.footerProps ? _vm.$props.footerProps : { itemsPerPageOptions: [10, 20, 30, -1] }},on:{"update:items-per-page":_vm.recalcHeaderStyles},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"-position-relative"},[(_vm.resetBtn.show)?_c('btn',{staticClass:"mb-3",class:{ 'reset-btn-absolute': _vm.resetBtn.isAbsolute },attrs:{"disabled":_vm.isFilterEmpty},on:{"click":_vm.onResetBtn}},[_vm._v("Reset filters")]):_vm._e()],1)]},proxy:true},{key:"header",fn:function(ref){
var ref_props = ref.props;
var someItems = ref_props.someItems;
var everyItem = ref_props.everyItem;
var groupBy = ref_props.options.groupBy;
var on = ref.on;
return [_c('thead',{ref:"thead"},[_c('tr',{ref:"header",staticClass:"header"},[(_vm.showSelect)?_c('th',{attrs:{"width":"20px"}},[(!_vm.selectLabel)?_c('v-checkbox',{attrs:{"value":everyItem,"indeterminate":someItems && !everyItem},on:{"change":on['toggle-select-all']}}):[_vm._v(_vm._s(_vm.selectLabel))]],2):_vm._e(),_vm._l((_vm.$props.headers),function(header){return _c('th',_vm._g(_vm._b({key:header.value,style:(_vm.headerStyles[header.value])},'th',Object.assign({}, header, {class: { fixed: header.fixed }}),false),
            header.sortable || header.sortable === undefined
              ? {
                  click: function () {
                    _vm.onSorting(header);
                  },
                }
              : {}
          ),[(header.sortable || header.sortable === undefined)?_c('span',{staticClass:"sortable",class:{
              active: (_vm.sorting && header.value === _vm.sorting.name) || groupBy.includes(header.value),
            }},[_vm._v(" "+_vm._s(header.text)+" "),(header.sortable || header.sortable === undefined)?_c('span',{staticClass:"sorting"},[_c('v-icon',{staticClass:"icon",class:{ active: _vm.isAscActive(header) },attrs:{"dense":""}},[_vm._v("fa-sort-up")]),_c('v-icon',{staticClass:"icon",class:{ active: _vm.isDescActive(header) },attrs:{"dense":""}},[_vm._v("fa-sort-down")])],1):_vm._e()]):(header.slot)?_vm._t(("header." + (header.value)),null,{"header":header}):[_vm._v(" "+_vm._s(header.text)+" ")],(header.width)?_c('span',{staticClass:"col-spacer",style:({ width: header.width })}):_vm._e()],2)})],2),(_vm.isFilter)?_c('tr',{ref:"subHeader",staticClass:"header filter",style:(_vm.subHeaderStyle)},[(_vm.showSelect)?_c('th',{style:(_vm.subHeaderStyle)}):_vm._e(),_vm._l((_vm.filters),function(filter){return _c('th',_vm._b({key:filter.name,style:(_vm.subHeaderStyle),attrs:{"width":filter.width}},'th',{ class: [{ fixed: filter.fixed }, filter.type] },false),[(filter.type && (filter.type === 'text' || filter.type === 'number' || filter.type === 'currency'))?_c('v-text-field',{attrs:{"outlined":"","dense":"","clearable":"","full-width":"","hide-details":""},on:{"input":function($event){return _vm.onFilter({ name: filter.name, value: filter.value, type: filter.type })}},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e(),(filter.type && filter.type === 'select')?_c('div',{staticClass:"select-wrapper"},[_c('v-select',{attrs:{"outlined":"","dense":"","multiple":"","items":filter.options,"full-width":"","hide-details":"","clearable":""},on:{"change":function($event){return _vm.onFilter({ name: filter.name, value: filter.value, type: filter.type, options: filter.options })}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var index = ref.index;
return [(index === 0)?_c('span',{staticClass:"selection"},[_vm._l((filter.value),function(f,i){return [_vm._v(_vm._s(i === 0 ? f : ', ' + f))]})],2):_vm._e()]}},{key:"item",fn:function(ref){
            var item = ref.item;
            var attrs = ref.attrs;
            var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"dense":"","hide-details":"","input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item)+" ")])],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}],null,true),model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}})],1):_vm._e(),(filter.type && filter.type === 'date')?_c('date-picker',{attrs:{"placeholder":"Date","dense":"","outlined":"","clearable":"","hide-details":""},on:{"change":_vm.onFilter},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e(),(filter.type && filter.type === 'slot')?_vm._t(("filter." + (filter.name)),null,{"callback":_vm.onFilter,"item":filter}):_vm._e()],2)})],2):_vm._e()])]}},{key:"group",fn:function(ref){
            var headers = ref.headers;
            var group = ref.group;
            var items = ref.items;
return [_c('tr',{staticClass:"v-row-group__header"},[_c('td',{attrs:{"colspan":_vm.$props.headers.length - 1}},[_c('btn',{attrs:{"color-type":"secondary","icon":""},on:{"click":function($event){return _vm.onGroupToggle(group)}}},[(_vm.isGroupExpanded(group))?_c('v-icon',[_vm._v("fa-chevron-up")]):_c('v-icon',[_vm._v("fa-chevron-down")])],1),_vm._v(_vm._s(group)+" ")],1),_c('td',{class:headers[headers.length - 1].cellClass},[_vm._t("group.action",null,{"group":group,"items":items})],2)]),(_vm.isGroupExpanded(group))?[(!_vm.isGroupContainChildren(items, headers))?_c('tr',{key:group},[_c('td',{staticClass:"empty-group-text",attrs:{"colspan":headers.length + 1}},[_vm._v(_vm._s(_vm.emptyGroupText))])]):_vm._l((items),function(item){return _c('tr',{key:item[_vm.$props.itemKey]},[_c('td'),_vm._l((headers),function(header){return _c('td',{key:header.value,class:header.cellClass},[(Object.keys(_vm.$scopedSlots).includes(("item." + (header.value))))?_vm._t(("item." + (header.value)),null,{"item":item}):[_vm._v(" "+_vm._s(item[header.value] || '')+" ")]],2)})],2)})]:_vm._e()]}},_vm._l((_vm.$props.headers),function(header){return {key:("item." + (header.value)),fn:function(ref){
            var item = ref.item;
return [(header.dataType === 'number')?[_vm._v(" "+_vm._s(_vm._f("number")(item[header.value]))+" ")]:(header.dataType === 'percentage')?[_vm._v(_vm._s(_vm._f("number")(item[header.value]))+"%")]:(header.dataType === 'currency')?[_c('span',{key:item[header.value],staticClass:"currency-cell"},[_vm._v(_vm._s(_vm._f("currency")(item[header.value])))])]:(header.dataType === 'longstring')?[_c('truncated-text',{key:item[header.value],attrs:{"text":item[header.value]}})]:[_vm._v(" "+_vm._s(item[header.value])+" ")]]}}}),_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true)},'v-data-table',Object.assign({}, _vm.$props, _vm.$attrs, {class: { 'h-scroll': _vm.hasHScroll }}),false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }