import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import PERMISSIONS from '@/enums/permissions';
import accessByPermissions from '@/services/userPermissions';
import Btn from '@/components/Btn/Btn.vue';
import CompletedComponents from '@/components/CompletedComponents/CompletedComponents.vue';
import ComponentsOfNeedTable from '@/components/ComponentsOfNeedTable/ComponentsOfNeedTable.vue';
import ExpenditureOptions from '@/components/ExpenditureOptions/ExpenditureOptions.vue';
import Modal from '@/components/Modal/Modal.vue';

export default {
  name: 'Components',
  components: { Btn, Modal, ExpenditureOptions, ComponentsOfNeedTable, CompletedComponents },
  props: {
    plan: {
      type: Object,
      default: null,
    },
    facilities: {
      type: Array,
      default: () => [],
    },
    activeTab: {
      type: Number,
      default: 0,
    },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
  },
  data: vm => ({
    tab: vm.activeTab,
  }),
  watch: {
    activeTab(value) {
      if (value !== this.tab) {
        this.tab = value;
      }
    },
    tab(value) {
      this.$emit('update:activeTab', value);
    },
  },
  computed: {
    componentFacilities() {
      return this.facilities?.length ? this.facilities.map(item => ({ id: item.id, name: item.name })) : [];
    },
    showCompletedComponents() {
      return accessByPermissions(PERMISSIONS.VIEW_COMPLETED_COMPONENTS, this.currentUser);
    },
    showExpenditureComponents() {
      return accessByPermissions(PERMISSIONS.VIEW_EXPENDITURE_OPTIONS, this.currentUser);
    },
  },
};
