import Vue from 'vue';
function currencyFilter(value) {
  if (value === null || value === undefined || value === '') return '';
  if (value === '--') {
    return value;
  }
  return (value * 10) % 10 ? `$ ${addComma(value.toFixed(2))}` : `$ ${addComma(value)}`;
}

function addComma(value) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

Vue.filter('currency', currencyFilter);

export default currencyFilter;
