import GENERATE_REPORTS from '@/graphql/mutations/generateReports.gql';
import eventHub from '@/utils/eventHub';
import ComponentsReportForm from './ComponentsReportForm/ComponentsReportForm.vue';
import { calculateBudget } from '@/services/componentBudget';
import { download } from '@/utils/file';

export default {
  name: 'ComponentsReport',
  components: { ComponentsReportForm },
  props: {
    plan: {
      type: Object,
    },
    fundingSourceOptions: {
      type: Object,
      default: null,
    },
    fundingSourceInvestmentIds: {
      type: Array,
      default: null,
    },
    components: {
      type: Array,
      default: null,
    },
    expenditureOption: {
      type: Array,
      default: null,
    },
    disableYearSlider: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    GENERATE_REPORTS,
    reportInfo: {
      name: null,
      reports: [],
      startYear: '',
      endYear: '',
    },
  }),
  methods: {
    downloadFile(_, { data: { generateReports } }) {
      if (generateReports) {
        download(generateReports.url, this.name);
        eventHub.$emit('show-snackbar', {
          color: 'success',
          text: `Reports were generated successfully!`,
        });
        this.$emit('done');
      } else {
        eventHub.$emit('show-snackbar', {
          color: 'error',
          text: `Error! Something went wrong... Please try later!`,
        });
      }
    },
    getInputComponents(components) {
      return components
        ? components.map(c => {
            const budget = calculateBudget(c, this.plan);
            return {
              id: c.id,
              planId: this.plan && this.plan.id,
              facility: (c.facility && c.facility.name) || null,
              system: (c.system && c.system.name) || null,
              categoryId: (c.system && c.system.category && c.system.category.id) || null,
              component: (c.component && c.component.name) || null,
              yearForImprovement: c.yearForImprovement,
              costPerUnit: c.costPerUnit,
              unit: c.unit && c.unit.name,
              quantityOfComponents: c.quantityOfComponents,
              priority: c.priority,
              ...budget,
              softCost: c.softCost,
              budgetaryNotes: c.budgetaryNotes,
              description: c.description,
              locationOfComponent: c.locationOfComponent,
              fundingSource: (c.fundingSource && c.fundingSource.name) || null,
              internalNotes: c.internalNotes,
              finCode: (c.system && c.system.finCode && parseInt(c.system.finCode)) || null,
              fundingSourceInvestmentId: (c.fundingSource && c.fundingSource.id) || null,
            };
          })
        : undefined;
    },
  },
  computed: {
    reportStartYear() {
      return this.plan ? parseInt(this.plan.financialInfo.planStartYear) : 1970;
    },
    inputComponents() {
      return this.getInputComponents(this.components);
    },
    inputExpenditureComponents() {
      return this.getInputComponents(this.expenditureOption);
    },
  },
};
