import { getObjValue } from './objExt';
import { formatDateISO } from './dateFormatter';

export function getDisplayedData(labels, data) {
  return labels.reduce((d, l) => {
    const value = getObjValue(data, l.value);
    let dynamicLabels = null;
    if (l.type === 'list') {
      dynamicLabels =
        value && value.length > 0
          ? value.map(v => {
              const listItemValue = getObjValue(v, l.labelName);
              return {
                label: listItemValue ? `${l.label} (${listItemValue.toLowerCase()})` : l.label,
                value: getObjValue(v, l.valueName),
              };
            })
          : [
              {
                ...l,
                value: null,
              },
            ];
    } else if (l.type === 'utcDate') {
      let dateValue = formatDateISO(value);
      dynamicLabels = [
        {
          ...l,
          value: dateValue,
        },
      ];
    }
    const item = dynamicLabels
      ? dynamicLabels
      : [
          {
            ...l,
            value: value,
          },
        ];
    d.push(...item);
    return d;
  }, []);
}
