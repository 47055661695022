import Btn from '@/components/Btn/Btn.vue';
import CLIENT_QUERY from '@/graphql/queries/client.gql';
import validateRules from '@/services/validateRules';
import ADD_FUNDING_SOURCE_MUTATION from '@/graphql/mutations/addFundingSource.gql';
import UPDATE_FUNDING_SOURCE_MUTATION from '@/graphql/mutations/updateFundingSource.gql';
import { isEqual } from 'lodash';
import eventHub from '@/utils/eventHub';
import NumberTextField from '@/components/NumberTextField/NumberTextField.vue';

export default {
  name: 'FundingSourceForm',
  components: { Btn, NumberTextField },
  props: {
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    fundingSource: { type: Object, default: null },
    planId: { type: String },
    clientId: { type: String },
    financialInfoId: { type: String },
  },
  data: function() {
    return {
      ...validateRules,
      form: (this.fundingSource && { ...this.fundingSource }) || {
        name: null,
        fundingEscalation: null,
        capitalFundingAmount: null,
      },
      nameRules: [v => !!v || 'Funding Source Name is Required'],
      capitalFundingAmountRules: [(v => v !== null && v !== undefined) || 'Revenue is Required'],
      fundingEscalationRules: [
        v => (v !== null && v !== '') || 'Funding Escalation is Required',
        validateRules.numberInRange(0, 100),
        validateRules.floatNumber(1),
      ],
      internalLoading: false,
      error: null,
      changes: new Set(),
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    onChange(key, value) {
      if (this.financialInfo) {
        if (!isEqual(this.financialInfo[key], value)) {
          this.changes.add(key);
        } else if (this.changes.has(key)) {
          this.changes.delete(key);
        }
      } else {
        this.changes.add(key);
      }
      if (this.active) {
        this.$emit('change');
      }
    },
    onSubmit() {
      this.error = null;
      if (this.validate() && this.changes.size > 0) {
        const { name, fundingEscalation, capitalFundingAmount } = this.form;
        let variables = {
          name,
          capitalFundingAmount,
          fundingEscalation: +fundingEscalation,
        };
        if (!this.fundingSource || !this.fundingSource.id) {
          variables.planId = this.planId;
          variables.financialInfoId = this.financialInfoId;
          this.addFundingSource({ ...variables });
        } else {
          variables.id = this.fundingSource.id;
          this.updateFundingSource({ ...variables });
        }
      } else if (this.validate() && this.changes.size === 0) {
        this.$emit('done');
      }
    },
    updateStoreFundingSource(store, { data: { updateFundingSource, addFundingSource } }) {
      let data = store.readQuery({
        query: CLIENT_QUERY,
        variables: { id: this.clientId || null },
      });
      let fundingSources = data.client.plans[0].financialInfo.fundingSources.slice();
      if (updateFundingSource) {
        data.client.plans[0].financialInfo.fundingSources = fundingSources.map(item => {
          if (item.id === updateFundingSource.id) {
            return Object.assign({}, item, updateFundingSource);
          }
          return item;
        });
      } else if (addFundingSource) {
        fundingSources.unshift({ ...addFundingSource });
        data.client.plans[0].financialInfo.fundingSources = fundingSources;
      }
      store.writeQuery({
        query: CLIENT_QUERY,
        variables: { id: this.clientId || null },
        data: data,
      });
    },
    addFundingSource(variables) {
      this.internalLoading = true;
      this.$apollo
        .mutate({
          mutation: ADD_FUNDING_SOURCE_MUTATION,
          variables: {
            input: variables,
          },
          update: this.updateStoreFundingSource,
        })
        .then(() => {
          eventHub.$emit('show-snackbar', {
            color: 'success',
            text: `Financial was successfully updated!`,
          });
          this.$emit('done');
        })
        .catch(e => {
          this.error = e;
        })
        .finally(() => {
          this.internalLoading = false;
        });
    },
    updateFundingSource(variables) {
      this.internalLoading = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_FUNDING_SOURCE_MUTATION,
          variables: {
            input: variables,
          },
          update: this.updateStoreFundingSource,
        })
        .then(() => {
          eventHub.$emit('show-snackbar', {
            color: 'success',
            text: `Financial info was updated successfully!`,
          });
          this.$emit('done');
        })
        .catch(e => {
          this.error = e;
        })
        .finally(() => {
          this.internalLoading = false;
        });
    },
  },
};
