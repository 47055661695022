import TruncatedText from '@/components/TruncatedText/TruncatedText.vue';

export default {
  name: 'InfoList',
  props: {
    data: { type: Array, default: [] },
    labelWidth: { type: String | Number },
  },
  components: { TruncatedText },
};
