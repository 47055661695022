import Btn from '@/components/Btn/Btn.vue';
import CLIENTS_QUERY from '@/graphql/queries/clients.gql';
import ClientList from '@/components/Clients/ClientList/ClientList.vue';
import Modal from '@/components/Modal/Modal.vue';
import PlansReport from '@/components/PlansReport/PlansReport.vue';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
export default {
  name: 'Clients',
  components: { Btn, ClientList, Modal, PlansReport },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-first',
    },
  },
  data: () => ({
    CLIENTS_QUERY,
    selected: [],
    currentUser: null,
    showGenerateReportBtn: false,
  }),
  computed: {
    showGenerateReportsBtn() {
      return accessByPermissions(PERMISSIONS.GENERATE_REPORT, this.currentUser);
    },
    disableReportBtn() {
      return this.selected.length === 0;
    },
    selectedPlans() {
      return this.selected.map(item => {
        let obj = item.plans[0];
        obj.name = item.name;
        return obj;
      });
    },
  },
  methods: {
    onSelect(items) {
      this.selected = items;
    },
    onReset() {
      this.selected = [];
      this.showGenerateReportBtn = false;
    },
    onCompare() {
      this.showGenerateReportBtn = true;
      this.selected = [];
    },
  },
};
