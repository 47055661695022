import Btn from '@/components/Btn/Btn.vue';

export default {
  name: 'MutateOptionsModal',
  components: { Btn },
  props: {
    btnName: {
      type: String,
      default: 'Update',
    },
    prevName: {
      type: String,
      default: 'Update',
    },
    variables: {
      type: Object,
    },
    mutation: null,
    onMutationCallback: null,
  },
  data: vm => ({
    name: vm.prevName ?? '',
    nameRules: [v => !!v || 'Name is Required'],
  }),
  computed: {
    isNameValid() {
      return this.name === null || this.name === '';
    },
  },
  methods: {
    onClonePlan(store, data) {
      typeof this.onMutationCallback === 'function' && this.onMutationCallback(store, data);
    },
  },
};
