var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"main_modal",attrs:{"fullscreen":"","persistent":true,"no-click-animation":true,"closeFunction":_vm.confirmBudgetPlanning},on:{"open":_vm.onModalOpen},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('btn',_vm._g(_vm._b({attrs:{"width":"255"},on:{"click":_vm.clearChanges}},'btn',attrs,false),on),[_vm._v("Expenditures Planning")])]}},{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('v-row',{staticClass:"mt-3 mb-3",attrs:{"no-gutters":"","justify":"start"}},[_c('h1',{staticClass:"mr-10"},[_vm._v("Expenditures Planning / "+_vm._s(_vm.title || _vm.client.name))]),(_vm.showGenerateReportBtn)?_c('components-report-modal',{attrs:{"disabled":_vm.loading || _vm.internalLoading,"disableYearSlider":_vm.isHideReportSlider,"plan":_vm.plan,"components":_vm.reportComponents,"expenditureOption":_vm.expenditureOption,"fundingSourceOptions":_vm.reportFundingSourceOptions,"fundingSourceInvestmentIds":_vm.reportFundingSourceInvestmentIds},on:{"done":close}}):_vm._e(),(_vm.canEditExpenditure)?_c('modal',{attrs:{"width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('btn',_vm._g(_vm._b({staticClass:"mr-6",attrs:{"disabled":_vm.loading || _vm.internalLoading || _vm.isNoChanges,"width":"302","color-type":"accent"}},'btn',attrs,false),on),[_vm._v("Save as Expenditures Option")])]}},{key:"default",fn:function(ref){
var close = ref.close;
return [_c('h3',{staticClass:"modal-title mb-3 -mt-2"},[_vm._v("Save Expenditures Option")]),_c('v-form',{ref:"options_form",attrs:{"lazy-validation":"","aria-busy":_vm.loading,"disabled":_vm.loading,"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.onOptionsSubmit(close)}}},[(_vm.isOptions)?_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.optionsRadioGroup),callback:function ($$v) {_vm.optionsRadioGroup=$$v},expression:"optionsRadioGroup"}},[_c('v-radio',{attrs:{"value":_vm.OPTIONS_RADIO.asExist,"label":"as Existing"}}),_c('v-radio',{attrs:{"value":_vm.OPTIONS_RADIO.asNew,"label":"as New"}})],1):_vm._e(),_c('v-text-field',{attrs:{"rules":_vm.optionNameRules,"label":"Name of Option","required":"","validate-on-blur":"","autocomplete":"off"},model:{value:(_vm.optionName),callback:function ($$v) {_vm.optionName=$$v},expression:"optionName"}}),_c('div',{staticClass:"mt-1 btns-bar"},[_c('btn',{attrs:{"width":"352px","type":"submit","disabled":_vm.loading}},[_vm._v("Save")])],1)],1)]}}],null,true)}):_vm._e(),_c('modal',{attrs:{"width":"500px","no-close":true,"persistent":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.canEdit)?_c('btn',_vm._g(_vm._b({staticClass:"mr-6",attrs:{"width":"170","disabled":_vm.loading || _vm.internalLoading || _vm.isNoChanges}},'btn',attrs,false),on),[_vm._v("Apply to Plan")]):_vm._e()]}},{key:"modal-header",fn:function(){return [_c('h3',{staticClass:"modal-title mt-8"},[_vm._v("Are you sure you want to save the changes and apply them to the plan?")])]},proxy:true},{key:"default",fn:function(ref){
var close = ref.close;
return [_c('div',{staticClass:"row mt-2"},[_c('btn',{staticClass:"mr-6",on:{"click":function($event){return _vm.onApplyToPlan(close)}}},[_vm._v("Yes")]),_vm._v(" "),_c('btn',{on:{"click":close}},[_vm._v("No")])],1)]}}],null,true)}),_c('modal',{ref:"confirm_modal",attrs:{"width":"500px","no-close":true,"persistent":true},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h3',{staticClass:"modal-title mt-8"},[_vm._v(" Are you sure you want to close the window? Unsaved data will be lost and not applied to the plan ")])]},proxy:true},{key:"default",fn:function(ref){
var close = ref.close;
return [_c('div',{staticClass:"row mt-2"},[_c('btn',{staticClass:"mr-6",on:{"click":_vm.closeBudgetPopup}},[_vm._v("Yes")]),_vm._v(" "),_c('btn',{on:{"click":close}},[_vm._v("No")])],1)]}}],null,true)}),_c('btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected.length > 0),expression:"selected.length > 0"}],attrs:{"width":"150","color-type":"accent","text":"","disabled":_vm.loading || _vm.internalLoading},on:{"click":_vm.onReset}},[_vm._v("Reset Included")])],1)]}},{key:"default",fn:function(ref){
var close = ref.close;
return [_c('div',{staticClass:"budget-planning mt-2",attrs:{"id":"budget-planning-wrapper"},on:{"done":close}},[_c('alert',{attrs:{"error":_vm.error}}),_c('v-expansion-panels',{attrs:{"flat":"","value":_vm.expanded,"multiple":""}},[_c('v-expansion-panel',[_c('expansion-panel-header',[_vm._v(" Clients & Components Budget ")]),_c('v-expansion-panel-content',[_c('div',{staticClass:"budget-table-container"},[_c('budget-table',{attrs:{"components":_vm.budgetTableComponents,"changes":_vm.changes,"plan":_vm.plan,"loading":_vm.loading,"customBudgetCells":_vm.changedBudgetCells},on:{"update:customBudgetCells":function($event){_vm.changedBudgetCells=$event},"update:custom-budget-cells":function($event){_vm.changedBudgetCells=$event},"fundingChange":_vm.onFundingChange}})],1)])],1),_c('v-expansion-panel',[_c('expansion-panel-header',[_vm._v(" Financial Information ")]),_c('v-expansion-panel-content',[(_vm.plan && _vm.plan.financialInfo)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"financial-info-details"},[_c('definition-list',{attrs:{"data":_vm.plan.financialInfo,"labels":_vm.financialLabels}})],1)],1):_c('v-row',{attrs:{"no-gutters":""}},[_vm._v("No financial information")])],1)],1)],1),_c('v-row',{staticClass:"mt-3",attrs:{"no-gutters":"","justify":"end"}},[_c('modal',{attrs:{"title":"Bulk year change","width":"380px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showBulkYearChangeBtn),expression:"showBulkYearChangeBtn"}],staticClass:"ml-6",attrs:{"width":"200"}},'btn',attrs,false),on),[_vm._v("Bulk year change ")])]}},{key:"default",fn:function(ref){
var close = ref.close;
return [_vm._v("` "),_c('v-select',{attrs:{"items":_vm.years,"label":"Improvement Year","required":""},model:{value:(_vm.selectedYear),callback:function ($$v) {_vm.selectedYear=$$v},expression:"selectedYear"}}),_c('btn',{attrs:{"width":"200"},on:{"click":function($event){_vm.bulkUpdateComponentsYear(_vm.selectedYear);
                close();}}},[_vm._v("Update components ")])]}}],null,true)}),_c('budget-calculation-info'),_c('data-table-columns',{attrs:{"headers":_vm.headers,"uId":_vm.TABLE_UID},model:{value:(_vm.selectedHeaders),callback:function ($$v) {_vm.selectedHeaders=$$v},expression:"selectedHeaders"}})],1),_c('component-list',{ref:"component_list",staticClass:"mt-3",attrs:{"plan":_vm.plan,"loading":_vm.loading,"components":_vm.components,"readonly":!_vm.canEdit,"changes":_vm.changes,"selected":_vm.selected,"filtered":_vm.filtered,"show-select":_vm.showGenerateReportBtn,"selected-headers":_vm.selectedHeaders},on:{"change":_vm.onChange,"select":_vm.onSelect,"filtered":_vm.onFilter,"initHeaders":_vm.onInitHeaders}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }