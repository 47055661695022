import Btn from '@/components/Btn/Btn.vue';
import CLIENT_QUERY from '@/graphql/queries/client.gql';
import validateRules from '@/services/validateRules';
import ADD_CAPITAL_INFUSION_MUTATION from '@/graphql/mutations/addCapitalInfusion.gql';
import UPDATE_CAPITAL_INFUSION_MUTATION from '@/graphql/mutations/updateCapitalInfusion.gql';
import { isEqual } from 'lodash';
import eventHub from '@/utils/eventHub';
import NumberTextField from '@/components/NumberTextField/NumberTextField.vue';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import { generateRangeArray } from '@/utils/generator';

export default {
  name: 'InfusionSourceForm',
  components: { Btn, DatePicker, NumberTextField },
  props: {
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    infusionSource: { type: Object, default: null },
    clientId: { type: String },
    fundingSources: { type: Array, default: [] },
    planStartYear: { type: String },
  },
  data: function() {
    return {
      ...validateRules,
      form: (this.infusionSource && { ...this.infusionSource }) || {
        fundingName: null,
        fundingSourceInvestmentId: null,
        capitalInfusionAmount: null,
        capitalInfusionYear: null,
      },
      internalLoading: false,
      error: null,
      changes: new Set(),
      fundingNameRules: [v => !!v || 'Capital Infusion Funding Source is Required'],
      capitalInfusionAmountRules: v => !!v || 'Capital Infusion Amount is Required',
      capitalInfusionYearRules: [v => !!v || 'Capital Infusion Year is Required'],
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    onChange(key, value) {
      if (this.infusionSource) {
        if (!isEqual(this.infusionSource[key], value)) {
          this.changes.add(key);
        } else if (this.changes.has(key)) {
          this.changes.delete(key);
        }
      } else {
        this.changes.add(key);
      }
      if (this.active) {
        this.$emit('change');
      }
    },
    onSubmit() {
      this.error = null;
      if (this.validate() && this.changes.size > 0) {
        const { fundingSourceInvestmentId, capitalInfusionAmount, capitalInfusionYear, fundingName } = this.form;
        let variables = {
          name: fundingName,
          fundingSourceInvestmentId: fundingSourceInvestmentId,
          capitalInfusionAmount: capitalInfusionAmount.toString(),
          capitalInfusionYear: capitalInfusionYear ? capitalInfusionYear : null,
        };
        if (!this.infusionSource || !this.infusionSource.id) {
          this.addInfusionSource({ ...variables });
        } else {
          variables.id = this.infusionSource.id;
          this.updateInfusionSource({ ...variables });
        }
      } else if (this.validate() && this.changes.size === 0) {
        this.$emit('done');
      }
    },
    updateStoreInfusionSource(store, { data: { updateCapitalInfusions, addCapitalInfusions } }) {
      let data = store.readQuery({
        query: CLIENT_QUERY,
        variables: { id: this.clientId || null },
      });
      let fundingSource = data.client.plans[0].financialInfo.fundingSources;
      let fundingSourceIndex = fundingSource.findIndex(
        item => item.id === updateCapitalInfusions?.fundingSourceInvestmentId || item.id === addCapitalInfusions?.fundingSourceInvestmentId
      );
      if (updateCapitalInfusions) {
        data.client.plans[0].financialInfo.fundingSources = fundingSource.map(source => {
          let newSource = source;
          if (source.capitalInfusions?.find(item => item.id === updateCapitalInfusions.id)) {
            newSource.capitalInfusions = source.capitalInfusions?.filter(item => item.id !== updateCapitalInfusions.id);
          }
          if (source.id === updateCapitalInfusions.fundingSourceInvestmentId) {
            newSource.capitalInfusions.push(updateCapitalInfusions);
          }
          return newSource;
        });
      } else if (addCapitalInfusions) {
        data.client.plans[0].financialInfo.fundingSources[fundingSourceIndex].capitalInfusions.unshift({
          ...addCapitalInfusions,
        });
      }
      store.writeQuery({
        query: CLIENT_QUERY,
        variables: { id: this.clientId || null },
        data: data,
      });
    },
    addInfusionSource(variables) {
      this.internalLoading = true;
      this.$apollo
        .mutate({
          mutation: ADD_CAPITAL_INFUSION_MUTATION,
          variables: {
            input: variables,
          },
          update: this.updateStoreInfusionSource,
        })
        .then(() => {
          eventHub.$emit('show-snackbar', {
            color: 'success',
            text: `Financial was successfully updated!`,
          });
          this.$emit('done');
        })
        .catch(e => {
          this.error = e;
        })
        .finally(() => {
          this.internalLoading = false;
        });
    },
    updateInfusionSource(variables) {
      this.internalLoading = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_CAPITAL_INFUSION_MUTATION,
          variables: {
            input: variables,
          },
          update: this.updateStoreInfusionSource,
        })
        .then(() => {
          eventHub.$emit('show-snackbar', {
            color: 'success',
            text: `Financial info was updated successfully!`,
          });
          this.$emit('done');
        })
        .catch(e => {
          this.error = e;
        })
        .finally(() => {
          this.internalLoading = false;
        });
    },
  },
  computed: {
    years() {
      if (this.planStartYear) {
        return generateRangeArray(+this.planStartYear, new Date().getFullYear() + 20).reverse();
      } else {
        return generateRangeArray(new Date().getFullYear() - 20, new Date().getFullYear() + 20).reverse();
      }
    },
  },
};
